import React from "react";
// css file starts here
import "../../assets/css/viewfloorplan.css";
// css file ends here
// import header and footer
import Header from "../../components/header";
import Footer from "../../components/footer";
// home page banner image
// page header start
import PageBanner from "../../components/PageBanner";
import Slider from "react-slick";
// page header ends
// images starts here
import plans from "../../assets/img/plans.png";
import plans2 from "../../assets/img/plans2.png";
import plans3 from "../../assets/img/plans3.png";

const ViewFloorplan = () => {
  // banner-slider
  var settings = {
    // className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
  };
  // banner-slider
  const pagebannerprops = [
    {
      pagename: "View Floor Plan",
      breadname: "View Floor Plan",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* view planes section wrapper starts here */}
      <section className="planes-section overflow-hidden">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <Slider {...settings}>
                <img src={plans} className="img-fluid" />
                <img src={plans2} className="img-fluid" />
                <img src={plans3} className="img-fluid" />
                <img src={plans} className="img-fluid" />
                <img src={plans2} className="img-fluid" />
                <img src={plans} className="img-fluid" />
                <img src={plans2} className="img-fluid" />
                <img src={plans3} className="img-fluid" />
                <img src={plans} className="img-fluid" />
                <img src={plans2} className="img-fluid" />
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* view planes section wrapper ends here */}
      <Footer />
    </>
  );
};

export default ViewFloorplan;
