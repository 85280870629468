import React from "react";
// css file starts here
import "../../assets/css/jobs.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";
import { Link } from "react-router-dom";

const jobs = () => {
  const pagebannerprops = [
    {
      pagename: "Careers",
      breadname: "Careers",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* job section starts here */}
      <section className="job-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="content-wrapper text-center">
                <h2>Jobs In Administration</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row jobs">
            <div className="col-lg-5">
              <div className="content-wrapper">
                <h5>Senior Manager</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="time-and-price">
                <h5 className="duration">
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                  Full time
                </h5>
                <h5 className="month">
                  <i class="fa fa-database" aria-hidden="true"></i>
                  $800/Month
                </h5>
              </div>
            </div>
            <div className="col-lg-2 view-and-date">
              <h4 className="date">5 September 2022</h4>
              <Link to="/jobs-details" className="btn">
                View Details
              </Link>
            </div>
          </div>
          <div className="row jobs">
            <div className="col-lg-5">
              <div className="content-wrapper">
                <h5>Senior Manager</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="time-and-price">
                <h5 className="duration">
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                  Full time
                </h5>
                <h5 className="month">
                  <i class="fa fa-database" aria-hidden="true"></i>
                  $800/Month
                </h5>
              </div>
            </div>
            <div className="col-lg-2 view-and-date">
              <h4 className="date">5 September 2022</h4>
              <Link to="/jobs-details" className="btn">
                View Details
              </Link>
            </div>
          </div>
          <div className="row jobs">
            <div className="col-lg-5">
              <div className="content-wrapper">
                <h5>Senior Manager</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="time-and-price">
                <h5 className="duration">
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                  Full time
                </h5>
                <h5 className="month">
                  <i class="fa fa-database" aria-hidden="true"></i>
                  $800/Month
                </h5>
              </div>
            </div>
            <div className="col-lg-2 view-and-date">
              <h4 className="date">5 September 2022</h4>
              <Link to="/jobs-details" className="btn">
                View Details
              </Link>
            </div>
          </div>
          <div className="row jobs">
            <div className="col-lg-5">
              <div className="content-wrapper">
                <h5>Senior Manager</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="time-and-price">
                <h5 className="duration">
                  <i class="fa fa-clock-o" aria-hidden="true"></i>
                  Full time
                </h5>
                <h5 className="month">
                  <i class="fa fa-database" aria-hidden="true"></i>
                  $800/Month
                </h5>
              </div>
            </div>
            <div className="col-lg-2 view-and-date">
              <h4 className="date">5 September 2022</h4>
              <Link to="/jobs-details" className="btn">
                View Details
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* job section ends here */}
      <Footer />
    </>
  );
};

export default jobs;
