import React, { useState } from "react";
import { Grommet, CheckBox } from "grommet";
import Slider from "react-slick";
import $ from "jquery";
import { Modal, Button } from "react-bootstrap";
// css file
import "../../assets/css/profile.css";
// import header and footer
import Header from "../../components/header";
import Footer from "../../components/footer";
// page header start
import PageBanner from "../../components/PageBanner";
// page header ends
// images
import profile_img from "../../assets/img/profile-img.png";
import edits from "../../assets/img/edit.svg";
import Eye from "../../assets/img/eye.svg";
import res from "../../assets/img/reserve.png";
import visa from "../../assets/img/1.png";
import google_play from "../../assets/img/2.png";
import master from "../../assets/img/3.png";
import paypal from "../../assets/img/4.png";
import close from "../../assets/img/close.svg";
import detailed_img from "../../assets/img/pop.png";
import wifi from "../../assets/img/wifi.svg";
import pool from "../../assets/img/pool.svg";
import sqrt from "../../assets/img/sqrt.svg";
import bedroom from "../../assets/img/bedroom.svg";
import bathroom from "../../assets/img/bathroom.svg";
import parking from "../../assets/img/parking.svg";

function MyVerticallyCenteredModal(props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="reserve-pop-up-0"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <span>
        <img className="close-btn" src={close} onClick={props.onHide} />
      </span>
      <Modal.Body>
        <section className="rented-appartment-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <Slider className="card-img" {...settings}>
                  <img src={detailed_img} className="img-fluid" />
                  <img src={detailed_img} className="img-fluid" />
                  <img src={detailed_img} className="img-fluid" />
                  <img src={detailed_img} className="img-fluid" />
                </Slider>
              </div>
              <div className="col-lg-6">
                <div className="content-wrapper">
                  <div className="property-name-and-night">
                    <h3>321 Lorem Ipsum</h3>
                    <h5>(Avialable)</h5>
                  </div>
                  <span>Studio</span>
                  <p className="price">$150/night</p>
                  <p>
                    <span className="date">Date</span>
                    <span>15-feb to 29-feb</span>
                  </p>
                  <div className="decription-wrapper">
                    <h4>Property description</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </p>

                    {/* <button className="btn" onClick={() => setModalShow(true)}>
                      Reserve
                    </button> */}
                    <div className="featurd mt-4">
                      <ul id="featured">
                        <li>
                          <img className="img-fluid" src={bedroom} />{" "}
                          <span>2 bedrooms</span>
                        </li>
                        <li>
                          <img className="img-fluid" src={bathroom} />{" "}
                          <span>1 bathroom</span>
                        </li>
                        <li>
                          <img className="img-fluid" src={wifi} />{" "}
                          <span>unit Wifi</span>
                        </li>
                        <li>
                          <img className="img-fluid" src={sqrt} />{" "}
                          <span>600 Sq yards</span>
                        </li>
                        <li>
                          <img className="img-fluid" src={parking} />{" "}
                          <span>Parking</span>
                        </li>
                        <li>
                          <img className="img-fluid" src={pool} />{" "}
                          <span>Pool</span>
                        </li>
                      </ul>
                      <ul id="contact-info">
                        <li>Questions? Give Us A Call</li>
                        <li>
                          <i className="fa fa-phone" aria-hidden="true"></i>
                          +00 123 456 789
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
}
const Profile = () => {
  const [modalShow, setModalShow] = React.useState(false);
  // hide and show profile page state
  const [edit, setEdit] = useState(false);
  //   tabs name set
  const [name, setname] = useState("My Profile");
  const [passwordShown, setPasswordShown] = useState(false);
  const [perchecked, setperChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [pet1checked, setPet1Checked] = useState(false);
  const [petchecked, setPetChecked] = useState(false);
  // cart form hide show
  const [card, setcard] = useState(true);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const pagebannerprops = [
    {
      pagename: "Profile",
      breadname: "Profile",
    },
  ];
  const balance = [
    {
      id: 1,
      Title: "321 Lorem Ipsum",
      Amount: 1.95,
      Transfer: "11 Feb, 1: 32 PM",
      Balance: "0",
    },
    {
      id: 2,
      Title: "321 Lorem Ipsum",
      Amount: 1.95,
      Transfer: "11 Feb, 1: 32 PM",
      Balance: "0",
    },
    {
      id: 3,
      Title: "321 Lorem Ipsum",
      Amount: 1.95,
      Transfer: "11 Feb, 1: 32 PM",
      Balance: "0",
    },
    {
      id: 4,
      Title: "321 Lorem Ipsum",
      Amount: 1.95,
      Transfer: "11 Feb, 1: 32 PM",
      Balance: "0",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* profile section start here */}
      <section className="profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <div className="content-wrapper">
                <h3 className="profile-heading">{name}</h3>
              </div>
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  className="nav-link active"
                  id="v-pills-home-tab"
                  data-toggle="pill"
                  href="#v-pills-home"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                  onClick={() => {
                    setname("My Profile");
                  }}
                >
                  My Profile
                </a>
                <a
                  className="nav-link"
                  id="v-pills-profile-tab"
                  data-toggle="pill"
                  href="#v-pills-profile"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                  onClick={() => {
                    setname("Reservations");
                  }}
                >
                  Reservations
                </a>
                <a
                  className="nav-link"
                  id="v-pills-messages-tab"
                  data-toggle="pill"
                  href="#v-pills-messages"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                  onClick={() => {
                    setname("Booking Details");
                  }}
                >
                  Booking Details
                </a>
                <a
                  className="nav-link"
                  id="v-pills-settings-tab"
                  data-toggle="pill"
                  href="#v-pills-settings"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                  onClick={() => {
                    setname("Add card");
                  }}
                >
                  Add card
                </a>
                {/* <a
                  className="nav-link"
                  id="v-pills-Wallet-tab"
                  data-toggle="pill"
                  href="#v-pills-Wallet"
                  role="tab"
                  aria-controls="v-pills-Wallet"
                  aria-selected="false"
                  onClick={() => {
                    setname("Wallet");
                  }}
                >
                  Wallet
                </a> */}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <section className="my-pro-wrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="profile-and-edit">
                          <div className="profile-wrap">
                            <div className="img-wrap">
                              <figure>
                                <a href="">
                                  <img
                                    src={profile_img}
                                    className="img-fluid"
                                  />
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </figure>
                            </div>
                            <div className="profile-name-wrp">
                              <h3 className="profile-name">John Doe</h3>
                              <h4 className="profile-city">Washington DC</h4>
                            </div>
                          </div>
                          <div className="edit-wrapper">
                            {edit ? (
                              <button
                                onClick={() => setEdit(false)}
                                className="edit"
                              >
                                Save Changes
                              </button>
                            ) : (
                              <button
                                onClick={() => setEdit(true)}
                                className="edit"
                              >
                                <img src={edits} /> Edit Profile
                              </button>
                            )}
                          </div>
                        </div>

                        {edit ? (
                          <div className="profile-form-wrapper-save">
                            <form>
                              <div className="form-group">
                                <label htmlFor="UserName">User Name</label>
                                <input
                                  placeholder="John"
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="PhoneNumber">
                                  Phone Number
                                </label>
                                <input
                                  placeholder="+00 123 456 789"
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="Date-of-Birth">
                                  Date of Birth
                                </label>
                                <input
                                  placeholder="11-Nov-22"
                                  className="form-control"
                                  type="date"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="Date-of-Birth">Password</label>
                                <input
                                  className="form-control"
                                  type={passwordShown ? "text" : "password"}
                                />
                                <img
                                  src={Eye}
                                  className="field-icon"
                                  onClick={togglePassword}
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="Date-of-Birth">
                                  Confirm Password
                                </label>
                                <input
                                  className="form-control"
                                  type={passwordShown ? "text" : "password"}
                                />
                                <img
                                  src={Eye}
                                  className="field-icon"
                                  onClick={togglePassword}
                                />
                              </div>
                              <div className="form-group row pt-4">
                                <div className="col-md-4 col-sm-6">
                                  <h6>Do you have a pet?</h6>
                                  <div className="form-check form-check-inline">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value="option1"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineCheckbox1"
                                  >
                                    Yes
                                  </label> */}

                                    <CheckBox
                                      checked={perchecked}
                                      label="Yes"
                                      onChange={(event) =>
                                        setperChecked(event.target.checked)
                                      }
                                      background="doc"
                                    />
                                  </div>
                                  <div className="form-check form-check-inline">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox2"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineCheckbox2"
                                  >
                                    No
                                  </label> */}
                                    <CheckBox
                                      checked={checked}
                                      label="No"
                                      onChange={(event) =>
                                        setChecked(event.target.checked)
                                      }
                                      background="doc"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                  <h6>Do you need Parking?</h6>
                                  <div className="form-check form-check-inline">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value="option1"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineCheckbox1"
                                  >
                                    Yes
                                  </label> */}

                                    <CheckBox
                                      checked={pet1checked}
                                      label="Yes"
                                      onChange={(event) =>
                                        setPet1Checked(event.target.checked)
                                      }
                                      background="doc"
                                    />
                                  </div>
                                  <div className="form-check form-check-inline">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox2"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineCheckbox2"
                                  >
                                    No
                                  </label> */}
                                    <CheckBox
                                      checked={petchecked}
                                      label="No"
                                      onChange={(event) =>
                                        setPetChecked(event.target.checked)
                                      }
                                      background="doc"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div className="profile-form-wrapper-edit">
                            <form>
                              <div className="form-group">
                                <label htmlFor="UserName">User Name</label>
                                <input
                                  placeholder="John"
                                  className="form-control"
                                  readOnly
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="PhoneNumber">
                                  Phone Number
                                </label>
                                <input
                                  placeholder="+00 123 456 789"
                                  className="form-control"
                                  readOnly
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="Date-of-Birth">
                                  Date of Birth
                                </label>
                                <input
                                  placeholder="11-Nov-22"
                                  className="form-control"
                                  readOnly
                                />
                              </div>
                              <div className="form-group row pt-4">
                                <div className="col-md-4 col-sm-6">
                                  <h6>Do you have a pet?</h6>
                                  <div className="form-check form-check-inline">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value="option1"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineCheckbox1"
                                  >
                                    Yes
                                  </label> */}

                                    <CheckBox
                                      checked={perchecked}
                                      label="Yes"
                                      onChange={(event) =>
                                        setperChecked(event.target.checked)
                                      }
                                      background="doc"
                                    />
                                  </div>
                                  <div className="form-check form-check-inline">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox2"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineCheckbox2"
                                  >
                                    No
                                  </label> */}
                                    <CheckBox
                                      checked={checked}
                                      label="No"
                                      onChange={(event) =>
                                        setChecked(event.target.checked)
                                      }
                                      background="doc"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                  <h6>Do you need Parking?</h6>
                                  <div className="form-check form-check-inline">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value="option1"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineCheckbox1"
                                  >
                                    Yes
                                  </label> */}

                                    <CheckBox
                                      checked={pet1checked}
                                      label="Yes"
                                      onChange={(event) =>
                                        setPet1Checked(event.target.checked)
                                      }
                                      background="doc"
                                    />
                                  </div>
                                  <div className="form-check form-check-inline">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox2"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    for="inlineCheckbox2"
                                  >
                                    No
                                  </label> */}
                                    <CheckBox
                                      checked={petchecked}
                                      label="No"
                                      onChange={(event) =>
                                        setPetChecked(event.target.checked)
                                      }
                                      background="doc"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <section className="reservation">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="profile-wrap">
                            <div className="img-wrap">
                              <figure>
                                <a href="">
                                  <img
                                    src={profile_img}
                                    className="img-fluid"
                                  />
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </figure>
                            </div>
                            <div className="profile-name-wrp">
                              <h3 className="profile-name">John Doe</h3>
                              <h4 className="profile-city">Washington DC</h4>
                            </div>
                          </div>
                          <div className="rever-wrap my-4">
                            <div className="row pill align-items-center">
                              <div className="col-md-4 p-0">
                                <div className="resrve-wrapper">
                                  <figure className="mb-0">
                                    <img src={res} className="img-fluid" />
                                  </figure>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="content-wrapper">
                                  <div className="title-and-price">
                                    <h4 className="reserve-title">
                                      321 Lorem Ipsum
                                    </h4>
                                    <h4 className="price">$2,100</h4>
                                  </div>
                                  <p className="des">
                                    333 River Street, Hoboken, Atlanta GA
                                  </p>
                                  <div className="further-details">
                                    <p className="night">$349/night</p>
                                    <div className="date-details">
                                      <p>Date: 15-feb to 29-feb</p>
                                      <button
                                        className="reser-btn"
                                        onClick={() => setModalShow(true)}
                                      >
                                        View Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                  <section className="booking-details">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="profile-wrap">
                            <div className="img-wrap">
                              <figure>
                                <a href="">
                                  <img
                                    src={profile_img}
                                    className="img-fluid"
                                  />
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </figure>
                            </div>
                            <div className="profile-name-wrp">
                              <h3 className="profile-name">John Doe</h3>
                              <h4 className="profile-city">Washington DC</h4>
                            </div>
                          </div>
                          <div className="booking-details-wrapper">
                            <div className="content-wrapper">
                              <h3>Booking Details</h3>
                            </div>
                            <div class="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th>Apt. Name</th>
                                    <th>Booking</th>
                                    <th>Check-In</th>
                                    <th>Check-out</th>
                                    <th>Total</th>
                                    <th>Status</th>
                                  </tr>
                                  <tr>
                                    <td>Lorem Ipsum</td>
                                    <td>321</td>
                                    <td>15-Feb-22</td>
                                    <td>29-Feb-22</td>
                                    <td>$2,100</td>
                                    <td>
                                      <span className="confirm">Confirmed</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="booking-details-wrapper">
                            <div className="content-wrapper">
                              <h3>Payment Details</h3>
                            </div>
                            <div class="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th>Apt. Name</th>
                                    <th>Booking</th>
                                    <th>Check-In</th>
                                    <th>Check-out</th>
                                    <th>Total</th>
                                    <th>Status</th>
                                  </tr>
                                  <tr>
                                    <td>Lorem Ipsum</td>
                                    <td>321</td>
                                    <td>15-Feb-22</td>
                                    <td>29-Feb-22</td>
                                    <td>$2,100</td>
                                    <td>
                                      <span className="confirm">Confirmed</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                >
                  <section className="addcartwrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="profile-wrap">
                          <div className="img-wrap">
                            <figure>
                              <a href="">
                                <img src={profile_img} className="img-fluid" />
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </figure>
                          </div>
                          <div className="profile-name-wrp">
                            <h3 className="profile-name">John Doe</h3>
                            <h4 className="profile-city">Washington DC</h4>
                          </div>
                        </div>
                        {card ? (
                          <div className="cart-form-wrapper">
                            <div className="content-wrapper">
                              <h3>Payment Details</h3>
                            </div>
                            {/* <form> */}
                            <div className="form-group">
                              <label>Name on Card</label>
                              <input
                                value="John"
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label>Card Number</label>
                              <input
                                value="+00 123 456 789"
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label>Zip Code</label>
                              <input
                                value="17592"
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="row ml-2 pt-2">
                              <div className="col-lg-8 p-0">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    value="option1"
                                    defaultChecked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  >
                                    <img className="p-4" src={visa} alt="" />
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio2"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio2"
                                  >
                                    <img
                                      className="p-2"
                                      src={google_play}
                                      alt=""
                                    />
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio3"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio3"
                                  >
                                    <img className="p-3" src={master} alt="" />
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio4"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio4"
                                  >
                                    <img src={paypal} alt="" />
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-4 text-right">
                                <button
                                  onClick={() => {
                                    setcard(false);
                                  }}
                                >
                                  Edit Details
                                </button>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        ) : (
                          <div className="add-cart-form-wrapper">
                            <div className="content-wrapper">
                              <h3>Add Card</h3>
                            </div>
                            {/* <form> */}
                            <div className="form-group">
                              <label>Name on Card</label>
                              <input
                                value="John"
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label>Card Number</label>
                              <input
                                value="+00 123 456 789"
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label>Zip Code</label>
                              <input
                                value="17592"
                                readOnly
                                className="form-control"
                              />
                            </div>
                            <div className="row ml-2 pt-2">
                              <div className="col-lg-8 p-0">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    value="option1"
                                    defaultChecked
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  >
                                    <img className="p-4" src={visa} alt="" />
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio2"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio2"
                                  >
                                    <img
                                      className="p-2"
                                      src={google_play}
                                      alt=""
                                    />
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio3"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio3"
                                  >
                                    <img className="p-3" src={master} alt="" />
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio4"
                                    value="option2"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio4"
                                  >
                                    <img src={paypal} alt="" />
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-4 text-right">
                                <button
                                  onClick={() => {
                                    setcard(true);
                                  }}
                                >
                                  Add Card
                                </button>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
                {/* <div
                  className="tab-pane fade"
                  id="v-pills-Wallet"
                  role="tabpanel"
                  aria-labelledby="v-pills-Wallet-tab"
                >
                  <section className="addcartwrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="profile-wrap">
                          <div className="img-wrap">
                            <figure>
                              <a href="">
                                <img src={profile_img} className="img-fluid" />
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </figure>
                          </div>
                          <div className="profile-name-wrp">
                            <h3 className="profile-name">John Doe</h3>
                            <h4 className="profile-city">Washington DC</h4>
                          </div>
                        </div>
                        <div className="balance-wrapper">
                          <div className="content-wrapper">
                            <h3>
                              Balance <span>$250</span>
                            </h3>
                          </div>
                          <table>
                            <tbody>
                              <tr>
                                <th>Title</th>
                                <th>Amount</th>
                                <th>Transfer</th>
                                <th>Balance</th>
                              </tr>
                              {balance.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{data.Title}</td>
                                    <td>{data.Amount}</td>
                                    <td>{data.Transfer}</td>
                                    <td>{data.Balance}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <nav
                            aria-label="Page navigation example"
                            className="crumbs mt-5"
                          >
                            <ul class="pagination justify-content-end">
                              <li class="page-item arrow">
                                <a
                                  class="page-link"
                                  href="#"
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true">
                                    <i
                                      class="fa fa-angle-left"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span class="sr-only">Previous</span>
                                </a>
                              </li>
                              <li class="page-item active">
                                <a class="page-link" href="#">
                                  1
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">
                                  2
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">
                                  3
                                </a>
                              </li>
                              <li class="page-item">
                                <a class="page-link" href="#">
                                  4
                                </a>
                              </li>
                              <li class="page-item arrow">
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">
                                    <i
                                      class="fa fa-angle-right"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </section>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* profile section ends here */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Footer />
    </>
  );
};

export default Profile;
