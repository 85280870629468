import React from "react";
// css file starts here
import "../../assets/css/faq.css";
// css file ends here
// page images starts
import faq_img from "../../assets/img/faq.png";
// page images starts
// import header and footer
import Header from "../../components/header";
import Footer from "../../components/footer";
// home page banner image
// page header start
import PageBanner from "../../components/PageBanner";
// page header ends

const faq = () => {
  const pagebannerprops = [
    {
      pagename: "FAQ's",
      breadname: "FAQ's",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* Accordian section starts here */}
      <section className="Faq-sec">
        <div className="container">
          {/* section heading */}
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="content-wrapper text-center">
                <h2>How can we help?</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          {/* section heading */}
          <div className="row pt-4">
            <div className="col-lg-6 my-auto">
              <div className="img-wrapper">
                <figure>
                  <img className="img-fluid" src={faq_img} />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="main">
                <div class="accordion" id="faq">
                  <div class="card">
                    <div class="card-header" id="faqhead1">
                      <a
                        href="#"
                        class="btn btn-header-link"
                        data-toggle="collapse"
                        data-target="#faq1"
                        aria-expanded="true"
                        aria-controls="faq1"
                      >
                        Lorem ipsum dolor sit amet, consectetur?
                      </a>
                    </div>

                    <div
                      id="faq1"
                      class="collapse show"
                      aria-labelledby="faqhead1"
                      data-parent="#faq"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec luctus tincidunt aliquam. Aliquam gravida massa at
                        sem vulputate interdum et vel eros. Maecenas eros enim,
                        tincidunt vel turpis vel, dapibus tempus nulla. Donec
                        vel nulla dui. Pellentesque sed ante sed ligula
                        hendrerit condimentum.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead2">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        Lorem Ipsum is simply dummy?
                      </a>
                    </div>

                    <div
                      id="faq2"
                      class="collapse"
                      aria-labelledby="faqhead2"
                      data-parent="#faq"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec luctus tincidunt aliquam. Aliquam gravida massa at
                        sem vulputate interdum et vel eros. Maecenas eros enim,
                        tincidunt vel turpis vel, dapibus tempus nulla. Donec
                        vel nulla dui. Pellentesque sed ante sed ligula
                        hendrerit condimentum.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead3">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq3"
                        aria-expanded="true"
                        aria-controls="faq3"
                      >
                        Lorem Ipsum is simply dummy?
                      </a>
                    </div>

                    <div
                      id="faq3"
                      class="collapse"
                      aria-labelledby="faqhead3"
                      data-parent="#faq"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec luctus tincidunt aliquam. Aliquam gravida massa at
                        sem vulputate interdum et vel eros. Maecenas eros enim,
                        tincidunt vel turpis vel, dapibus tempus nulla. Donec
                        vel nulla dui. Pellentesque sed ante sed ligula
                        hendrerit condimentum.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead3">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq4"
                        aria-expanded="true"
                        aria-controls="faq4"
                      >
                        Lorem Ipsum is simply dummy?
                      </a>
                    </div>

                    <div
                      id="faq4"
                      class="collapse"
                      aria-labelledby="faqhead4"
                      data-parent="#faq"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec luctus tincidunt aliquam. Aliquam gravida massa at
                        sem vulputate interdum et vel eros. Maecenas eros enim,
                        tincidunt vel turpis vel, dapibus tempus nulla. Donec
                        vel nulla dui. Pellentesque sed ante sed ligula
                        hendrerit condimentum.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead3">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq5"
                        aria-expanded="true"
                        aria-controls="faq5"
                      >
                        Lorem Ipsum is simply dummy?
                      </a>
                    </div>

                    <div
                      id="faq5"
                      class="collapse"
                      aria-labelledby="faqhead5"
                      data-parent="#faq"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec luctus tincidunt aliquam. Aliquam gravida massa at
                        sem vulputate interdum et vel eros. Maecenas eros enim,
                        tincidunt vel turpis vel, dapibus tempus nulla. Donec
                        vel nulla dui. Pellentesque sed ante sed ligula
                        hendrerit condimentum.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="faqhead3">
                      <a
                        href="#"
                        class="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq6"
                        aria-expanded="true"
                        aria-controls="faq6"
                      >
                        Lorem Ipsum is simply dummy?
                      </a>
                    </div>

                    <div
                      id="faq6"
                      class="collapse"
                      aria-labelledby="faqhead6"
                      data-parent="#faq"
                    >
                      <div class="card-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec luctus tincidunt aliquam. Aliquam gravida massa at
                        sem vulputate interdum et vel eros. Maecenas eros enim,
                        tincidunt vel turpis vel, dapibus tempus nulla. Donec
                        vel nulla dui. Pellentesque sed ante sed ligula
                        hendrerit condimentum.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Accordian section ends here */}
      <Footer />
    </>
  );
};

export default faq;
