import React from "react";
// css file
import "../../assets/css/auth.css";
// images
import Auth from "../../assets/img/auth.png";
import Authlogo from "../../assets/img/auth-logo.png";
import { Link } from "react-router-dom";

const Signin = () => {
  return (
    <>
      <section className="auth-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 align-self-center p-0">
              <div className="container">
                <div className="row">
                  <div className="col-xl-2"></div>
                  <div className="col-xl-8">
                    <div className="form-wrapper">
                      <h1>Sign In</h1>
                      <h4>Welcoming to Future of Living</h4>
                      <h6>
                        Not a Member ? <a href="/signup">Sign Up</a>
                      </h6>
                      <form action="/profile" >
                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Email"
                            type="email"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="Password"
                            type="Password"
                          />
                        </div>
                        <div className="form-group">
                          <button className="form-control form-btn">
                            Sign In
                          </button>
                        </div>
                        <div className="form-group ter">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck1"
                              defaultChecked
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck1"
                            >
                              Remember Me
                            </label>
                          </div>
                          <Link className="forgot" to="/entermail">Forgot Password ?</Link>
                        </div>
                      </form>
                    </div>
                    <div className="term-sec">
                      <p>
                        By Creating Account you are agree to our{" "}
                        <a href="/Terms"> Terms & conditions</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="img-wrap">
                <figure>
                  <img src={Auth} className="img-fluid" />
                  <img src={Authlogo} className="img-fluid logo" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signin;
