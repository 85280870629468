import React, { useState } from "react";
import $ from "jquery";
// css file
import "../../assets/css/auth.css";
// images
import Auth from "../../assets/img/auth.png";
import Authlogo from "../../assets/img/auth-logo.png";
import { Link } from "react-router-dom";
import Eye from "../../assets/img/eye.svg";

const Signup = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <section className="auth-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 align-self-center p-0">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    <div className="form-wrapper">
                      <h1>Sign Up</h1>
                      <h4>Welcoming to Future of Living</h4>
                      <h6>
                        Already Member? <Link to="/signin">Sign In</Link>
                      </h6>
                      <form>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <input
                              className="form-control"
                              placeholder="First Name"
                              type="text"
                            />
                          </div>
                          <div className="col-lg-6">
                            <input
                              className="form-control"
                              placeholder="Last Name"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <input
                              className="form-control"
                              placeholder="Email"
                              type="email"
                            />
                          </div>
                          <div className="col-lg-6">
                            <input
                              className="form-control"
                              placeholder="Phone Number"
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <input
                              className="form-control"
                              placeholder="Password"
                              type={passwordShown ? "text" : "password"}
                              id="password-field"
                            />
                            <img
                              src={Eye}
                              className="field-icon"
                              onClick={togglePassword}
                            />
                          </div>
                          <div className="col-lg-6">
                            <input
                              className="form-control"
                              placeholder="Confirm Password"
                              type={passwordShown ? "text" : "password"}
                            />
                            <img
                              src={Eye}
                              className="field-icon"
                              onClick={togglePassword}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="code" >
                            Enter Referral Code and Get 5% Discount on your
                            First Reservation <span className="any" >(If Any)</span>
                          </label>
                          <input className="form-control" type="text" placeholder="Enter Referral Code" />
                        </div>
                        <div className="form-group">
                          <button className="form-control form-btn">
                            Sign Up
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="term-sec mt-0">
                      <p>
                        By Creating Account you are agree to our{" "}
                        <a href="/Terms"> Terms & conditions</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="img-wrap">
                <figure>
                  <img src={Auth} className="img-fluid" />
                  <img src={Authlogo} className="img-fluid logo" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
