import React from "react";
// css file starts here
import "../../assets/css/jobs-details.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";
import { Link } from "react-router-dom";

const jobs_details = () => {
  const pagebannerprops = [
    {
      pagename: "Careers",
      breadname: "Careers",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* jobs deials section starst here */}
      <section className="job-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <h2>Senior Manager</h2>
                <div className="cont-wrap">
                  <h5>5 September 2022</h5>
                  <div className="time-and-price">
                    <h5 className="duration">
                      <i class="fa fa-clock-o" aria-hidden="true"></i>
                      Full time
                    </h5>
                    <h5 className="month">
                      <i class="fa fa-database" aria-hidden="true"></i>
                      $800/Month
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row des-row">
            <div className="col-lg-12">
              <div className="content-wrap">
                <h3>Description</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <h3>Resposibilities</h3>
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                  <li>Vestibulum auctor dapibus neque.</li>
                  <li>Nunc dignissim risus id metus.</li>
                  <li>Cras ornare tristique elit.</li>
                  <li>Vivamus vestibulum ntulla nec ante.</li>
                  <li>Praesent placerat risus quis eros.</li>
                  <li>Fusce pellentesque suscipit nibh.</li>
                </ul>
                <Link to="/applynow" className="btn">Apply Now</Link> 
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* jobs deials section end here */}
      <Footer />
    </>
  );
};

export default jobs_details;
