import React from "react";
import { Link } from "react-router-dom";

const blog_card = (props) => {
  const { blogcontent } = props;
  return (
    <Link to="/blog-detail">
      <div className="blog-card-wrapper">
        <div className="blog-img-wrapper">
          <figure>
            <img className="img-fluid" src={blogcontent.img} />
          </figure>
        </div>
        <div className="blog-content-wrapper">
          <h3 className="blog-name">{blogcontent.blogname}</h3>
          <p className="blog-des">{blogcontent.blogdes}</p>
          <div className="blog-date-cta">
            <h5>
              <i class="fa fa-calendar-o" aria-hidden="true"></i>
              {blogcontent.postdate}
            </h5>
            <h5 className="cta">
              Read More
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </h5>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default blog_card;
