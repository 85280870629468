import React from "react";
// quotes images import 
import quotes_right from '../assets/img/upper-quote.svg'
import quotes_left from '../assets/img/lower-quote.svg'
const testimonials_card = (props) => {
 
  const { Reviewitem } = props;
  return (
    <>
        <div className="testimonials-wrapper">
          <div className="quoteupper">
            <img src={quotes_right} alt="" />
          </div>
          <div className="testimonails-quote">
            <p>{Reviewitem.review}</p>
          </div>
          <div className="quotelower text-right">
            <img src={quotes_left} alt="" />
          </div>
          <div className="client-name-wrapper">
            <div className="client-img">
              <img src={Reviewitem.img} alt="" />
            </div>
            <div className="client-details-wrapper">
              <h4>{Reviewitem.name}</h4>
              <p> {Reviewitem.designation}</p>
            </div>
          </div>
        </div>
    </>
  );
};

export default testimonials_card;
