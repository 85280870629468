import React, { useEffect, useState, useRef } from "react";
// css file
import "../../assets/css/works.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";
// images
import copybtn from "../../assets/img/copy-btn.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Refer_a_friend = () => {
  function showWarn(){
    toast.success("Text Copied");
  };
  const [CopySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }

  const pagebannerprops = [
    {
      pagename: "Refer a Friend",
      breadname: "Refer a Friend",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* how it works section starts here */}
      <ToastContainer />
      <section className="works">
        <div className="container">
          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="content-wrapper">
                <h2>Refer And Be A Good Friend</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-3"></div>
            <div className="col-lg-4">
              <div className="content-wrapper">
                <div className="copy-text d-flex">
                  <h4>
                    Refer and Get 250$ credit on First Reservation{" "}
                    <a href="#">Sign In</a>
                  </h4>
                  <button onClick={copyToClipboard}>
                    <img onClick={showWarn}  className="img-fluid" src={copybtn} />
                  </button>
                </div>
                <div className="link">
                  <input
                    className="w-100"
                    value="http://www.example.com/?refer=250$"
                    readOnly
                    ref={textAreaRef}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* points section starts here */}
          <div className="row works-list ref-list py-4">
            <div className="col-lg-12 mb-5">
              <h2>How to Refer</h2>
            </div>
            <div className="col-lg-1">
              <span className="number">1</span>
            </div>
            <div className="col-lg-11">
              <div className="content-wrapper">
                <h3>Invite a Friend</h3>
                <p>Share your referral code with your friend.</p>
              </div>
            </div>
          </div>
          <div className="row works-list ref-list py-4">
            <div className="col-lg-1">
              <span className="number">2</span>
            </div>
            <div className="col-lg-11">
              <div className="content-wrapper">
                <h3>Your Friend Books a Landing</h3>
                <p>
                  Using your code, they will see the 5% Discount at check-out.
                </p>
              </div>
            </div>
          </div>
          <div className="row works-list ref-list py-4">
            <div className="col-lg-1">
              <span className="number">3</span>
            </div>
            <div className="col-lg-11">
              <div className="content-wrapper">
                <h3>Your Friend Lives with Landing</h3>
                <p>
                  After your friend moves in you will receive $250 in Landing
                  credit.
                </p>
              </div>
            </div>
          </div>
          <div className="row works-list ref-list py-4">
            <div className="col-lg-1">
              <span className="number">4</span>
            </div>
            <div className="col-lg-11">
              <div className="content-wrapper">
                <h3>You will receive $250 in credit</h3>
                <p>
                  After your friend moves in you will receive $250 in landing
                  credit
                </p>
              </div>
            </div>
          </div>
          {/* points section ends here */}
        </div>
      </section>
      {/* how it works section starts here */}
      <Footer />
    </>
  );
};

export default Refer_a_friend;
