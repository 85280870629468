import React from "react";
import Slider from "react-slick/lib/slider";
import { Grommet, DateInput } from "grommet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
// import header and footer
import Header from "../../components/header";
import Footer from "../../components/footer";
// home page banner image
import Banner_img from "../../assets/img/banner-img.png";
// home page banner image
// icons section images starts
import icon1 from "../../assets/img/icon1.png";
import icon2 from "../../assets/img/icon2.png";
import icon3 from "../../assets/img/icon3.png";
// icons section images ends
// future of living section starts here
import living1 from "../../assets/img/living1.png";
import living2 from "../../assets/img/living2.png";
import living3 from "../../assets/img/living3.png";
import slide_img from "../../assets/img/pi.png";
// future of living section ends here
// Featured apparments image starts here
import appartments1 from "../../assets/img/apartments1.png";
import wifi from "../../assets/img/wifi.svg";
import bathroom from "../../assets/img/bathroom.svg";
import bedroom from "../../assets/img/bedroom.svg";
// Featured apparments image ends here
// best rates section images starts here
import rates1 from "../../assets/img/rates1.png";
import rates2 from "../../assets/img/rates2.png";
import rates3 from "../../assets/img/rates3.png";
import rates4 from "../../assets/img/rates4.png";
import rates5 from "../../assets/img/rates5.png";
import rates6 from "../../assets/img/rates6.png";
import rates7 from "../../assets/img/rates7.png";
import rates8 from "../../assets/img/rates8.png";
// best rates section images ends here
// reinventing section images starts here
import minimalist from "../../assets/img/minimalist.png";
import explore from "../../assets/img/explorer.png";
import travel from "../../assets/img/travel.png";
import family from "../../assets/img/family.png";
import blog from "../../assets/img/blod.png";
// testimonails component
import Testimonials from "../../components/testimonials";
import Appartments_card from "../../components/Appartments";
import { Link } from "react-router-dom";
import "animate.css";
const Home = () => {
  // banner-slider
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // banner-slider
  // Furnished-slider
  var Furnished = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],  
  };
  // Furnished-slider
  // Appartments list
  var Appartments = [
    {
      id: 1,
      name: "The Verano",
      img: slide_img,
      des: "Stamford, CT",
      sqt: "665 sq ft",
      bathroom: "1 Bath",
      bedroom: "1 Bedroom",
      price: "349",
      availability: "March 04",
    },
    {
      id: 2,
      name: "The Verano",
      img: slide_img,
      des: "Stamford, CT",
      sqt: "665 sq ft",
      bathroom: "1 Bath",
      bedroom: "1 Bedroom",
      price: "349",
      availability: "March 04",
    },
    {
      id: 3,
      name: "The Verano",
      img: slide_img,
      des: "Stamford, CT",
      sqt: "665 sq ft",
      bathroom: "1 Bath",
      bedroom: "1 Bedroom",
      price: "349",
      availability: "March 04",
    },
    {
      id: 4,
      name: "The Verano",
      img: slide_img,
      des: "Stamford, CT",
      sqt: "665 sq ft",
      bathroom: "1 Bath",
      bedroom: "1 Bedroom",
      price: "349",
      availability: "March 04",
    },
    // {
    //   id: 5,
    //   name: "The Verano",
    //   img: appartments1,
    //   des: "Stamford, CT",
    //   price: "349",
    //   wifi: " unit Wifi",
    //   bathroom: " bathroom",
    //   bedroom: " 2 Bedroom",
    // },
    // {
    //   id: 6,
    //   name: "321 Lorem Ipsum",
    //   img: appartments1,
    //   des: "246 East 74th Street, New York, NY 10021",
    //   price: "349",
    //   wifi: " unit Wifi",
    //   bathroom: " bathroom",
    //   bedroom: " 2 Bedroom",
    // },
    // {
    //   id: 7,
    //   name: "321 Lorem Ipsum",
    //   img: appartments1,
    //   des: "246 East 74th Street, New York, NY 10021",
    //   price: "349",
    //   wifi: " unit Wifi",
    //   bathroom: " bathroom",
    //   bedroom: " 2 Bedroom",
    // },
    // {
    //   id: 8,
    //   name: "321 Lorem Ipsum",
    //   img: appartments1,
    //   des: "246 East 74th Street, New York, NY 10021",
    //   price: "349",
    //   wifi: " unit Wifi",
    //   bathroom: " bathroom",
    //   bedroom: " 2 Bedroom",
    // },
  ];
  // Appartments list
  // blog content
  var blog_content = [
    {
      id: 1,
      name: "The minimalist",
      des: "Guests looking to live & travel light that opt for furnished over unfurnished, a minimalism mindset, and an environmentally-conscious approach to living.",
      img: minimalist,
    },
    {
      id: 2,
      name: "The explorer",
      des: "Renters eager to hop around, excited to experiment, and keen to explore a new apartment, neighborhood or city without ever needing to commit.",
      img: explore,
    },
    {
      id: 3,
      name: "The Business travel",
      des: "Guests looking to live & travel light that opt for furnished over unfurnished, a minimalism mindset, and an environmentally-conscious approach to living.",
      img: travel,
    },
  ];
  // blog content
  return (
    <>
      <Header />
      <section className="banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="banner-slider">
                <Slider {...settings}>
                  <div className="banner-slider-item">
                    <div className="banner-img-wrapper">
                      <figure>
                        <img src={Banner_img} className="img-fluid" alt="" />
                      </figure>
                    </div>
                    <div className="container">
                      <div className="banner-content-wrapper   animate__fadeInLeft">
                        <h1>
                          Find your <br />
                          <span>Dream Home</span>
                        </h1>
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text.
                        </p>
                        <div className="banner-form-wrapper">
                          <select
                            defaultValue=""
                            className="form-control mt-3 destination"
                          >
                            <option>Choose your destination</option>
                            <option>Austin, TX</option>
                            <option>Birmingham, AL</option>
                            <option>Dallas, TX</option>
                            <option>Las Vegas, NV</option>
                          </select>
                        </div>
                        {/* <div className="banner-form-wrapper">
                          <form className="form-inline">
                            <div className="form-group">
                              <label htmlFor="staticEmail2">
                                Location <span>Where to?</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                              /> 
                              <select defaultValue="" className="form-control mt-3">
                                <option>Atlanta, GA</option>
                                <option>Austin, TX</option>
                                <option>Birmingham, AL</option>
                                <option>Dallas, TX</option>
                                <option>Las Vegas, NV</option>
                              </select>
                            </div>
                            <div className="form-group mx-sm-3">
                              <label htmlFor="inputPassword2">
                                Dates <span>How long?</span>
                              </label>
                              <input
                                type="date"
                                className="form-control mt-3"
                                placeholder=""
                              />
                            </div>
                            <button type="submit" className="btn">
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>
                              Search
                            </button>
                          </form>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // <!-- icon-section starts here --> */}
      {/* <section className="icon">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="icon-wrapper text-center">
                <figure>
                  <img src={icon1} className="img-fluid" alt="" />
                </figure>
                <div className="icon-content-wrapper">
                  <p>
                    Lorem ipsum dolor sit <br /> amet, consectetur.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-wrapper text-center">
                <figure>
                  <img src={icon2} className="img-fluid" alt="" />
                </figure>
                <div className="icon-content-wrapper">
                  <p>
                    Lorem ipsum dolor sit <br /> amet, consectetur.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="icon-wrapper text-center">
                <figure>
                  <img src={icon3} className="img-fluid" alt="" />
                </figure>
                <div className="icon-content-wrapper">
                  <p>
                    Lorem ipsum dolor sit <br /> amet, consectetur.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* //  <!-- icon-section ends here --> */}
      {/* <!-- future of living section starts here --> */}
      <section className="future">
        <div className="container">
          {/* <!-- heading starts here --> */}
          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
              <div className="content-wrapper text-center   animate__fadeInDown">
                <h2>Your Home away from Home</h2>
                <p className="pt-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem <br /> Ipsum has been the
                  industrys standard dummy text ever since the 1500s.
                </p>
              </div>
            </div>
            <div className="col-sm-1"></div>
          </div>
          {/* <!-- heading ends here --> */}
          {/* <!-- images boxes starts here --> */}
          <div className="row pt-5">
            <div className="col-lg-8 px-md-0">
              <div className="future-image-box   animate__fadeInLeft">
                <figure>
                  <img src={living1} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="future-image-box   animate__fadeInDown">
                <figure>
                  <img src={living2} className="img-fluid" alt="" />
                </figure>
              </div>
              <div className="future-image-box   animate__fadeInRight">
                <figure>
                  <img src={living3} className="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
          {/* <!-- images boxes starts here --> */}
        </div>
      </section>
      {/* <!-- future of living section ends here --> */}
      {/* <!-- furnished appartment section starts here --> */}
      <section className="furnished">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 text-center">
              <div className="content-wrapper   animate__fadeInDown">
                {/* <h6>Featured</h6> */}
                <h2>Featured Apartments</h2>
                <p className="pt-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem <br /> Ipsum has been the
                  industrys standard dummy text ever since the 1500s, when an{" "}
                  <br /> unknown printer took a galley of type and scrambled it
                  to make a type specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          <Slider className="row apparments" {...Furnished}>
            {Appartments.map((data, index) => {
              return (
                <div key={index} className="col-lg-12">
                  <Appartments_card appartment={data} />
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
      {/* <!-- furnished appartment section ends here --> */}
      {/* <!-- best rates section starts here --> */}
      {/* <section className="best-rates">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <h2>
                  Get the best rates and even more freedom <br /> with Landing
                  Explorer
                </h2>
              </div>
            </div>
          </div>
          <h5 className="text-white">Every Landing Includes</h5>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="rates-wrapper">
                <figure>
                  <img src={rates1} className="img-fluid" alt="" />
                </figure>
                <h5>Comfy, in-house designed furnishings</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rates-wrapper">
                <figure>
                  <img src={rates2} className="img-fluid" alt="" />
                </figure>
                <h5>Luxurious sleep experience</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rates-wrapper">
                <figure>
                  <img src={rates3} className="img-fluid" alt="" />
                </figure>
                <h5>A fully-equipped kitchen</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rates-wrapper">
                <figure>
                  <img src={rates4} className="img-fluid" alt="" />
                </figure>
                <h5>Dedicated workspaces</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rates-wrapper">
                <figure>
                  <img src={rates5} className="img-fluid" alt="" />
                </figure>
                <h5>Smart TVs + fast Wifi</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rates-wrapper">
                <figure>
                  <img src={rates6} className="img-fluid" alt="" />
                </figure>
                <h5>Premium amenities</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rates-wrapper">
                <figure>
                  <img src={rates7} className="img-fluid" alt="" />
                </figure>
                <h5>Plenty of storage</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="rates-wrapper">
                <figure>
                  <img src={rates8} className="img-fluid" alt="" />
                </figure>
                <h5>Thoughtful finishing touches</h5>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- best rates section ends here --> */}
      {/* <!-- reinvention section starts here --> */}
      <section className="reinventing">
        <div className="container">
          <div className="row pb-5">
            <div className="col-sm-12">
              <h2 className="text-center   animate__fadeInDown">
                Short term rentals are for...
              </h2>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-7">
              <div className="reinvention-card-img">
                <div className="grid-img">
                  <figure>
                    <img
                      src={travel}
                      className="img-fluid short-imgs   animate__fadeInLeft"
                      alt=""
                    />
                  </figure>
                  <div className="reinvention-card-img">
                    <figure>
                      <img
                        src={explore}
                        className="img-fluid short-imgs   animate__fadeInDown"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
                <div className="grid-img">
                  <figure>
                    <img
                      src={blog}
                      className="img-fluid short-imgs   animate__fadeInLeft"
                      alt=""
                    />
                  </figure>
                  <div className="reinvention-card-img">
                    <figure>
                      <img
                        src={family}
                        className="img-fluid short-imgs   animate__fadeInDown"
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <h5 className="  animate__fadeInDown">
                Business Travelers
              </h5>
              <p className="  animate__fadeInRight">
                Employees looking for more space & less cost than hotels via
                partnerships with HR & travel teams at 500+ companies in the
                world.
              </p>
              <h5 className="  animate__fadeInRight">
                Relocation
              </h5>
              <p className="  animate__fadeInRight">
                Guests looking to live & travel that opt got furnished over
                unfurnished. a minimalism mindset, and on
                environmentally-conscious approach to living.
              </p>
              <h5 className="  animate__fadeInRight">
                Tourists
              </h5>
              <p className="  animate__fadeInRight">
                Guests looking to live & travel that opt got furnished over
                unfurnished. a minimalism mindset, and on
                environmentally-conscious approach to living.
              </p>
              <h5 className="  animate__fadeInRight">
                Personal Needs
              </h5>
              <p className="  animate__fadeInRight">
                Renters eager to hop around, excited to experiment, and keen to
                explore a new apartment, neighbourhood or city without ever
                needing to commit.
              </p>
            </div> */}
            <div className="col-lg-3 col-md-6">
              <div className="blog_box">
                <div className="imgBox">
                  <img className="img-fluid" src={travel} alt="" />
                </div>
                <div className="content-box">
                  <h5 class="  animate__fadeInRight">
                    Business Travelers
                  </h5>
                  <p className="  animate__fadeInRight">
                    Employees looking for more space & less cost than hotels via
                    partnerships with HR & travel teams at 500+ companies in the
                    world.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="blog_box">
                <div className="imgBox">
                  <img className="img-fluid" src={blog} alt="" />
                </div>
                <div className="content-box">
                  <h5 className="  animate__fadeInRight">
                    Tourists
                  </h5>
                  <p className="  animate__fadeInRight">
                    Guests looking to live & travel that opt got furnished over
                    unfurnished. a minimalism mindset, and on
                    environmentally-conscious approach to living.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="blog_box">
                <div className="imgBox">
                  <img className="img-fluid" src={family} alt="" />
                </div>
                <div className="content-box">
                  <h5 className="  animate__fadeInRight">
                    Personal Needs
                  </h5>
                  <p className="  animate__fadeInRight">
                    Renters eager to hop around, excited to experiment, and keen
                    to explore a new apartment, neighbourhood or city without
                    ever needing to commit.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="blog_box">
                <div className="imgBox">
                  <img className="img-fluid" src={explore} alt="" />
                </div>
                <div className="content-box">
                  <h5 className="  animate__fadeInRight">
                    Relocation
                  </h5>
                  <p className="  animate__fadeInRight">
                    Guests looking to live & travel that opt got furnished over
                    unfurnished. a minimalism mindset, and on
                    environmentally-conscious approach to living.
                  </p>
                </div>
              </div>
            </div>
            {/* {blog_content.map((data, index) => {
              return (
                <div key={index} className="col-lg-4">
                  <Link to="/blog-detail">
                    <div className="reinvention-card-wrapper">
                      <a href="#">
                        <div className="reinvention-card-img">
                          <figure>
                            <img src={data.img} className="img-fluid" alt="" />
                          </figure>
                        </div>
                        <div className="reinvention-card-content">
                          <h3>{data.name}</h3>
                          <p>{data.des}</p>
                        </div>
                      </a>
                    </div>
                  </Link>
                </div>
              );
            })} */}

            {/* <div className="col-lg-4">
              <div className="reinvention-card-wrapper">
                <a href="#">
                  <div className="reinvention-card-img">
                    <figure>
                      <img src={explore} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="reinvention-card-content">
                    <h3>The explorer</h3>
                    <p>
                      Renters eager to hop around, excited to experiment, and
                      keen to explore a new apartment, neighborhood or city
                      without ever needing to commit.
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="reinvention-card-wrapper">
                <a href="#">
                  <div className="reinvention-card-img">
                    <figure>
                      <img src={travel} className="img-fluid" alt="" />
                    </figure>
                  </div>
                  <div className="reinvention-card-content">
                    <h3>The Business travel</h3>
                    <p>
                      Employees looking for more space & less cost than hotels
                      via partnerships with HR & travel teams at 500+ companies
                      in the world.
                    </p>
                  </div>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <!-- reinvention section ends here --> */}
      {/* // <!-- testimonials section starts here --> */}
      <Testimonials />
      {/* // <!-- testimonials section ends here --> */}
      <Footer />
    </>
  );
};

export default Home;
