import React from "react";
// footer logo
import footer_logo from "../assets/img/footer-logo.png";
// footer social icons
import facebook from "../assets/img/facebook.svg";
import googleplus from "../assets/img/googleplus.svg";
import twitter from "../assets/img/twitter.svg";
import { Link } from "react-router-dom";

const footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="footer-widget-1">
                <a href="index.php">
                  <figure>
                    <img src={footer_logo} alt="" />
                  </figure>
                </a>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry Lorem Ipsum has been the industrys.
                </p>
                <ul id="social">
                  <li>
                    <a href="#">
                      <img src={facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={googleplus} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={twitter} alt="" />
                    </a>
                  </li>
                </ul>
                <div className="ter-wrapper">
                  <span>
                    <Link to="/terms">Terms and Conditions </Link> &nbsp; |
                    &nbsp;
                    <Link to="/Privacy"> Privacy Policy</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget-2">
                <h3>Quick Links</h3>
                <ul id="quick-links">
                  {/* <li>
                    <Link to="/">Home</Link>
                  </li> */}
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/BecomePartner">Our Propertise</Link>
                  </li>
                  <li>
                    <Link to="/Careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/works">How It Works</Link>
                  </li>
                  <li>
                    <Link to="/BecomePartner">Contact Us</Link>
                  </li>
                  {/* <li>
                    <Link to="/BecomeHost">FAQ's</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/blog">Blogs</Link>
                  </li> */}

                  {/* <li>
                  <Link to="/rentalappartment">Find Home</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/profile">My Profile</Link>
                  </li> */}

                  {/* <li>
                    <Link to="/refer-a-friend">Refer a Friend</Link>
                  </li> */}

                  {/* <li>
                    <Link to="/BecomeHost">Become a Host</Link>
                  </li>
                  <li>
                    <Link to="/BecomePartner">Become A Partner</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget-3">
                <h3>Contact Info</h3>
                <ul className="contact-info">
                  <li>
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    Lorem ipsum, <br /> adipiscing elit, <br /> conse, 9876
                  </li>
                  <li>
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    Lorem ipsum, <br /> adipiscing elit, <br /> conse, 9876
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer-widget-4">
                <h3></h3>
                <ul id="contact" className="mt-5">
                  <li>
                    <a href="mailto:info@example.com">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      info<span style={{ fontfamily: "Poppins" }}>@</span>
                      example.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:+01234456890">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      Call Us: <span style={{ fontfamily: "Poppins" }}>+</span>0
                      12356 890
                    </a>
                  </li>
                </ul>
                {/* <Link to="/signin" className="btn">
                  Sign In
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div id="copyright">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <p>© 2022 Our East Rentals. All Right Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default footer;
