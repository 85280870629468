import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
// css file
import "../../assets/css/payment.css";
// import header and footer
import Header from "../../components/header";
import Footer from "../../components/footer";
// images import
import visa from "../../assets/img/1.png";
import google_play from "../../assets/img/2.png";
import master from "../../assets/img/3.png";
import paypal from "../../assets/img/4.png";
import close from "../../assets/img/close.svg";
import view_plan from '../../assets/img/floor-plan.png';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="reserve-pop-up"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <span>
        <img className="close-btn" src={close} onClick={props.onHide} />
      </span>
      <Modal.Body>
        <div className="modal-form-wrapper">
          <h4 className="text-center">View Floor Plan</h4>
          <div className="img-wrapper">
            <figure>
              <img src={view_plan} className="img-fluid" />
            </figure>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const Payment = () => {
  const [modalShow, setModalShow] = useState(false);
  const onSubmit = (event) => {
    event.preventDefault();
    console.log("submission prevented");
  };
  return (
    <>
      <Header />
      {/* payment section wrapper starts here */}
      <section className="payment">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-7">
              <div className="payment-form-wrapper">
                <div className="payment-content-wrapper">
                  <h3>Payment Page</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of
                  </p>
                  <h3>Add Card</h3>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label>Name on Card</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="John"
                    />
                  </div>
                  <div className="form-group">
                    <label>Card Number</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="+00 123 456 789"
                    />
                  </div>
                  <div className="row ml-2 pt-2">
                    <div className="col-lg-12 p-0">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="option1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          <img className="p-4" src={visa} alt="" />
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="option2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          <img className="p-2" src={google_play} alt="" />
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio3"
                          value="option2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio3"
                        >
                          <img className="p-3" src={master} alt="" />
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio4"
                          value="option2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio4"
                        >
                          <img src={paypal} alt="" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group pt-3">
                    <div className="confir">
                      <p>
                        By confirming your stay, you agree to the{" "}
                        <a href="#">Terms and Condition</a>
                      </p>
                    </div>
                  </div>
                  <div className="form-group pt-5">
                    <h4>Member Agreement</h4>
                    <div className="agree-wrapper">
                      <span>
                        By confirming your stay, you agree to the terms of the
                        East out rentals
                      </span>
                      <div className="custom-control custom-checkbox my-1 mr-sm-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      onClick={() => setModalShow(true)}
                      className="form-btn"
                    >
                      Complete Reservation
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4 align-self-center">
              <div className="promo-code-wrapper">
                <form className="form-inline">
                  <label>Enter Promo Code</label>
                  <div className="form-group row">
                    <div className="col-md-8">
                      <input className="form-control" placeholder="F5F10" />
                    </div>
                    <div className="col-md-4">
                      <button className="form-btn">Apply</button>
                    </div>
                  </div>
                  <div className="payment-break-down-wrapper">
                    <h3>Payment Breakdown</h3>
                    <ul className="duties">
                      <li>
                        <span>Total Rent</span>
                        <span>$2,100.00</span>
                      </li>
                      <li>
                        <span>Utilities</span>
                        <span>$100.00</span>
                      </li>
                      <li>
                        <span>Monthly total</span>
                        <span>$100.00</span>
                      </li>
                      <li>
                        <span>Promo Code</span>
                        <span>$5.00</span>
                      </li>
                    </ul>
                    <ul className="total">
                      <li>
                        <span>Total</span>
                        <span>$1,950.00</span>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* payment section wrapper ends here */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Footer />
    </>
  );
};

export default Payment;
