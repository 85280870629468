import React from "react";
import Slider from "react-slick/lib/slider";
import wifi from "../assets/img/wifi.svg";
import bathroom from "../assets/img/bathroom.svg";
import bedroom from "../assets/img/bedroom.svg";
import { Link } from "react-router-dom";

const RentalCard = (props) => {
  const { appartment } = props;
  // banner-slider
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // banner-slider
  return (
    <>
      <Link to="/RentalDetail">
        <div className="card-wrapper">
          <Slider className="card-img" {...settings}>
            <img src={appartment.img} className="img-fluid" alt="" />
            <img src={appartment.img} className="img-fluid" alt="" />
            <img src={appartment.img} className="img-fluid" alt="" />
            <img src={appartment.img} className="img-fluid" alt="" />
          </Slider>
          <a href="#">
            <div className="card-content-wrapper">
              <div className="d-flex justify-content-between">
                <h4 className="appartment-name">{appartment.name}</h4>
                <p className="price">${appartment.price}/night</p>
              </div>
              <p className="description">
                {appartment.des}
                <span className="green">
                  {appartment.availabel == true
                    ? " (Available)"
                    : " (Not Available)"}
                </span>
              </p>
              <ul className="specification">
                <li>
                  <img src={wifi} alt="" /> {appartment.wifi}
                </li>
                <li>
                  <img src={bathroom} alt="" /> {appartment.bathroom}
                </li>
                <li>
                  <img src={bedroom} alt="" /> {appartment.bedroom}
                </li>
              </ul>
            </div>
          </a>
        </div>
      </Link>
    </>
  );
};

export default RentalCard;
