import React, { useEffect } from "react";
import logo from "../assets/img/logo-dark.png";
import { Link } from "react-router-dom";

const header = () => {
  // document.body.addEventListener("load", flipclassadd);
  // function flipclassadd() {
  //   var logo_id = document.querySelector(".animate__tad");
  //   logo_id.classList.add("animate__animated", "animate__tada");
  // }
  // setInterval(() => {
  //   flipclassadd();
  //   var logo_id = document.querySelector(".animate__tad");
  //   logo_id.classList.remove("animate__animated","animate__tada");
  // }, 3000);
  return (
    <header id="header">
      <div className="header-menu-wrapper">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-4">
              <Link to="/">
                <img
                  id="logo"
                  src={logo}
                  className="logo"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-8">
              {/* test */}
              <div className="menu-item-wrapper">
                <ul id="menu">
                  {/* <li className="nav-item">
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      Our Properties
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      How it Works
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/rentalappartment" className="nav-link">
                      Find Home
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link to="/faq" className="nav-link">
                      FAQ's
                    </Link>
                  </li> */}

                  <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                      Contact Us
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/Signin" className="nav-link btn">
                      Sign In
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="canvas-icon">
                <i className="fa fa-bars" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-header">
          <div className="cancel">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <ul className="mobile-nav">
            <li className="nav-item">
              <Link to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/about">About Us</Link>
            </li>
            <li className="nav-item">
              <Link to="/rentalappartment">Find Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/faq">FAQ's</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact">Contact Us</Link>
            </li>
            <li className="nav-item">
              <Link to="/Signin">Sign In</Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default header;
