import React from "react";
// css file
import "../../assets/css/contact.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";
// icons
import phone_icon from "../../assets/img/phone.svg";
import mail_icon from "../../assets/img/mail.svg";
import location_icon from "../../assets/img/location.svg";

const contact = () => {
  const pagebannerprops = [
    {
      pagename: "Contact Us",
      breadname: "Contact Us",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* contact us form starts here */}
      <section className="Contact-form-wrapper">
        <div className="container">
          {/* heading section starts here */}
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="content-wrapper text-center">
                <h2>Let’s Create Progress Together</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          {/* heading section ends here */}
          {/* contact form start here */}
          <div className="row pt-5">
            <div className="col-lg-6">
              <div className="form-wrapper">
                <form>
                  <div className="form-group">
                    <label htmlFor="YourName">Your Name</label>
                    <input
                      type="text"
                      placeholder="John Doe"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="YourName">Select a Subject</label>
                    <input
                      type="text"
                      placeholder="Lorem Ipsum"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="YourName">Your Email Address</label>
                    <input
                      type="email"
                      placeholder="info@example.com"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="YourName">Contact Number</label>
                    <input
                      type="number"
                      placeholder="+0 1234 456 890"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="YourName">Discription</label>
                    <textarea
                      rows="5"
                      placeholder="Lorem Ipsum"
                      className="form-control"
                      cols="100"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <button className="form-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="100%"
                    height="643"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                </div>
              </div>
             
            </div>
          </div>
          {/* contact form start here */}
        </div>
      </section>
      {/* contact us form ends here */}
      {/* contact info section starts here */}
      <section className="icons-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="info-wrapper text-center">
                <figure>
                  <img className="img-fluid" src={phone_icon} />
                </figure>
                <h4>Talk to us</h4>
                <p>Toll-Free: +0 1234 456 890</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info-wrapper text-center">
                <figure>
                  <img className="img-fluid" src={mail_icon} />
                </figure>
                <h4>Contact us</h4>
                <p>Email: info@example.com</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info-wrapper text-center loc">
                <figure>
                  <img className="img-fluid" src={location_icon} />
                </figure>
                <h4>Location</h4>
                <p>Unit 2 Lenton Business Centre <br/> Lenton Washington, DC</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* contact info section ends here */}
      <Footer />
    </>
  );
};

export default contact;
