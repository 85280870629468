import React from "react";
// css file
import "../../assets/css/auth.css";
// images
import Auth from "../../assets/img/auth.png";
import Authlogo from "../../assets/img/auth-logo.png";

const Enterotp = () => {
  return (
    <>
      <section className="auth-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 align-self-center p-0">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    <div className="form-wrapper text-center">
                      <h1>Enter OTP</h1>
                      <h4 className="enter-mail">
                        <span>Enter 4 digit Code</span>{" "}
                      </h4>
                      <form action="/enterpass" >
                        <div className="form-group otp">
                          <input
                            className="form-control text-center"
                            type="number"
                          />
                           <input
                            className="form-control text-center"
                            type="number"
                          />
                           <input
                            className="form-control text-center"
                            type="number"
                          />
                           <input
                            className="form-control text-center"
                            type="number"
                          />
                        </div>
                        <div className="form-group">
                          <button className="form-control form-btn">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="term-sec">
                      <p>
                        By Creating Account you are agree to our{" "}
                        <a href="/Terms"> Terms & conditions</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 p-0">
              <div className="img-wrap">
                <figure>
                  <img src={Auth} className="img-fluid" />
                  <img src={Authlogo} className="img-fluid logo" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Enterotp;
