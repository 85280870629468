import React from "react";
// css file
import "../../assets/css/RentalAppartments.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";
import RentalCard from "../../components/RentalCard";
// images
import appartments1 from "../../assets/img/apartments1.png";
import appartments2 from "../../assets/img/rental2.png";
import appartments3 from "../../assets/img/rental3.png";
import appartments4 from "../../assets/img/rental4.png";
import checkout from "../../assets/img/checkout.png";

const RentalFurnishedAppartments = () => {
  // Appartments list
  var Appartments = [
    {
      id: 1,
      name: "321 Lorem Ipsum",
      img: appartments1,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: true,
    },
    {
      id: 2,
      name: "333 River Street",
      img: appartments2,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: true,
    },
    {
      id: 3,
      name: "321 Lorem Ipsum",
      img: appartments3,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: true,
    },
    {
      id: 4,
      name: "333 River Street",
      img: appartments4,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: true,
    },
    {
      id: 5,
      name: "321 Lorem Ipsum",
      img: appartments3,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: true,
    },
    {
      id: 6,
      name: "333 River Street",
      img: appartments4,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: false,
    },
  ];
  // Appartments list
  const pagebannerprops = [
    {
      pagename: "Rentals & Furnished Appartments",
      breadname: "Rentals & Furnished Appartments",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* Rental Appartment section starts here */}
      <section className="furnished">
        <div className="container">
          <div className="row filter-wrap pb-5">
            <div className="col-lg-12">
              <div className="button-wrapper">
                <button className="btn">
                  <span className="green">City</span>{" "}
                  <span className="city">Atlanta</span>
                </button>
                <button className="btn">
                  <span className="green">Date</span>{" "}
                  <span className="date">15-feb to 29-feb</span>
                </button>
                <button className="btn">
                  <span className="green">Bedroom</span>{" "}
                </button>
                <button className="btn">
                  <span className="green">Neighborhoods</span>{" "}
                </button>
                <button className="btn">
                  <span className="green">Amenities</span>{" "}
                </button>
                <button className="btn btn-yellow">
                  <span className="yellow">Filter </span>{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="select-wrapper">
                <label>Sort By:</label>
                <select defaultValue="Availability" className="form-control">
                  <option>Availability</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row RentalCard">
            <div className="col-lg-7">
              <div className="row">
                {Appartments.map((data, index) => {
                  return (
                    <div className="col-md-6">
                      <RentalCard key={index} appartment={data} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="100%"
                    height="840"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                </div>
              </div>
              <div className="box-wrapper">
                <div className="img-wrapper">
                  <figure>
                    <img className="img-fluid" src={checkout} />
                  </figure>
                </div>
                <div className="box-content">
                  <h4>
                    Want to check out <br />
                    Furnished Appartments In LAS Vegas, NV
                  </h4>
                  <a href="#">
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="Page navigation example" className="crumbs">
                <ul class="pagination">
                  <li class="page-item arrow">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                      </span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li class="page-item arrow">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* Rental Appartment section starts here */}
      <Footer />
    </>
  );
};

export default RentalFurnishedAppartments;
