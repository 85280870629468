import React from "react";
import { Link } from "react-router-dom";
const PageBanner = (props) => {
  let currentUrl = window.location.href.split("/");
  console.log("currentUrl", currentUrl);
  const { pagebannerprops } = props;
  return (
    <section className="PageHeader">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-heading-wrapper">
              <h1>{pagebannerprops.pagename}</h1>
              <span className="bread-cumbs">
                <Link to="/">{currentUrl[2]="Home"}</Link> /{" "}
                {/* <span>{pagebannerprops.breadname}</span> */}
                <span>{currentUrl[3]}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageBanner;
