import React from "react";
// css starts here
import "../../assets/css/reservation-form.css";
// import header and footer
import Header from "../../components/header";
import Footer from "../../components/footer";
// home page banner image
import special1 from "../../assets/img/special1.png";
import special2 from "../../assets/img/special2.png";

const ReservationForm = () => {
  return (
    <>
      <Header />
      {/* reservation form starts here */}
      <section className="reserv-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-7">
              <div className="form-wrapper">
                <div className="content-wrapper">
                  <h3>Reservation Form</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of
                  </p>
                </div>
                <form action="/payment">
                  <div className="form-group">
                    <label>User Name</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="John Doe"
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="+00 123 456 789"
                    />
                  </div>
                  <div className="form-group">
                    <label>Date of Birth</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Johndoe@email.com"
                    />
                  </div>
                  <div className="form-group">
                    <label>Enter Referral link</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="http://www.example.com/?refer=250$"
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label>Check-in</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="15 feb"
                      />
                    </div>
                    <div className="col-lg-6">
                      <label>Check-out</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="25 feb"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Estimated Amountk</label>
                    <h4 className="amount">2,100</h4>
                  </div>
                  <div className="form-group row check-box-wrapper">
                    <div className="col-lg-4">
                      <label>Do you have a pet?</label> <br />
                      <div className="custom-control custom-checkbox form-check-inline">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          &nbsp; Yes
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox form-check-inline ml-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                         
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          &nbsp; No
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <label>Do you need Parking?</label> <br />
                      <div className="custom-control custom-checkbox form-check-inline">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          defaultChecked
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          &nbsp; Yes
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox form-check-inline ml-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                          
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          &nbsp; No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button className="btn form-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="special-offers-wrapper">
                <div className="special-offers-heading-wrapper">
                  <h3>Special Offers</h3>
                </div>
                <div className="special-offers-card-wrapper">
                  <div className="img-wrapper">
                    <figure>
                      <img src={special1} className="img-fluid" />
                    </figure>
                  </div>
                  <div className="special-content">
                    <h5 className="offer-title">321 Lorem Ipsum</h5>
                    <p className="des">
                      246 East 74th Street, New York, NY 10021
                      <br />
                      <span className="price">$349/night</span>
                    </p>
                  </div>
                </div>
                <div className="special-offers-card-wrapper">
                  <div className="img-wrapper">
                    <figure>
                      <img src={special2} className="img-fluid" />
                    </figure>
                  </div>
                  <div className="special-content">
                    <h5 className="offer-title">321 Lorem Ipsum</h5>
                    <p className="des">
                      246 East 74th Street, New York, NY 10021
                      <br />
                      <span className="price">$349/night</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* reservation form ends here */}
      <Footer />
    </>
  );
};

export default ReservationForm;
