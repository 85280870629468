import React from "react";
// css file
import "../../assets/css/auth.css";
// images
import Auth from "../../assets/img/auth.png";
import Authlogo from "../../assets/img/auth-logo.png";

const EnterNewpass = () => {
  return (
    <>
      <section className="auth-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 align-self-center p-0">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    <div className="form-wrapper text-center">
                      <h1>New Password</h1>
                      <h4 className="enter-mail">
                        <span>Create a new Password</span>{" "}
                      </h4>
                      <form action="/signin" >
                        <div className="form-group">
                          <input
                            className="form-control text-center"
                            placeholder="New Password"
                            type="password"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control text-center"
                            placeholder="Re-enter New Password"
                            type="password"
                          />
                        </div>
                        <div className="form-group">
                          <button className="form-control form-btn">
                          Continue
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="term-sec">
                      <p>
                        By Creating Account you are agree to our{" "}
                        <a href="/Terms"> Terms & conditions</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <div className="img-wrap">
                <figure>
                  <img src={Auth} className="img-fluid" />
                  <img src={Authlogo} className="img-fluid logo" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EnterNewpass;
