import React, { useState } from "react";
import Slider from "react-slick";
import { Modal, Button } from "react-bootstrap";
// css file
import "../../assets/css/rentaldetail.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";
import Appartments_card from "../../components/Appartments";
// images
import luxaryrooms from "../../assets/img/luxary-rooms.png";
import appartments1 from "../../assets/img/apartments1.png";
import appartments2 from "../../assets/img/rental2.png";
import appartments3 from "../../assets/img/rental3.png";
import detailed_img from "../../assets/img/detailed.png";
import wifi from "../../assets/img/wifi.svg";
import pool from "../../assets/img/pool.svg";
import sqrt from "../../assets/img/sqrt.svg";
import bedroom from "../../assets/img/bedroom.svg";
import bathroom from "../../assets/img/bathroom.svg";
import parking from "../../assets/img/parking.svg";
import close from "../../assets/img/close.svg";
import { Link } from "react-router-dom";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="reserve-pop-up"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <span>
        <img className="close-btn" src={close} onClick={props.onHide} />
      </span>
      <Modal.Body>
        <div className="modal-form-wrapper">
          <h4>Request a Tour</h4>
          <form className="mt-4">
            <div className="form=group">
              <label htmlFor="email">Full Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="+00 123 456 789"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Phone</label>
              <input
                className="form-control"
                type="number"
                placeholder="17592q"
              />
            </div>
            <div className="form-group">
              <button className="btn form-btn">Request a Tour</button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const RentalFurnishedAppartmentsDetail = () => {
  const [modalShow, setModalShow] = React.useState(false);
  // banner-slider
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // banner-slider
  // Appartments list
  var Appartments = [
    {
      id: 1,
      name: "321 Lorem Ipsum",
      img: appartments1,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: true,
    },
    {
      id: 2,
      name: "333 River Street",
      img: appartments2,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: true,
    },
    {
      id: 3,
      name: "321 Lorem Ipsum",
      img: appartments3,
      des: "246 East 74th Street, Town A New York, NY 10021 Furnished Appartment",
      price: "349",
      wifi: " unit Wifi",
      bathroom: " bathroom",
      bedroom: " 2 Bedroom",
      availabel: true,
    },
  ];
  // Appartments list
  // Appartments list
  const pagebannerprops = [
    {
      pagename: "Rentals & Furnished Appartments",
      breadname: "Rentals & Furnished Appartments",
    },
  ];

  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* rental appartment-section starts here*/}
      <section className="rented-appartment-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <Slider className="card-img" {...settings}>
                <img src={detailed_img} className="img-fluid" />
                <img src={detailed_img} className="img-fluid" />
                <img src={detailed_img} className="img-fluid" />
              </Slider>
              <div className="button-wrapper">
                <button className="btn">Request a Tour</button>
                <button className="btn">View Floor Plan</button>
              </div>
              <div className="featurd mt-4">
                <ul id="featured">
                  <li>
                    <img className="img-fluid" src={bedroom} />{" "}
                    <span>2 bedrooms</span>
                  </li>
                  <li>
                    <img className="img-fluid" src={bathroom} />{" "}
                    <span>1 bathroom</span>
                  </li>
                  <li>
                    <img className="img-fluid" src={wifi} />{" "}
                    <span>unit Wifi</span>
                  </li>
                  <li>
                    <img className="img-fluid" src={sqrt} />{" "}
                    <span>600 Sq yards</span>
                  </li>
                  <li>
                    <img className="img-fluid" src={parking} />{" "}
                    <span>Parking</span>
                  </li>
                  <li>
                    <img className="img-fluid" src={pool} /> <span>Pool</span>
                  </li>
                </ul>
                <ul id="contact-info">
                  {/* <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    333 River Street, Hoboken
                  </li> */}
                  <li>Questions? Give Us A Call</li>
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i>
                    +00 123 456 789
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <div className="property-name-and-night">
                  <h3>321 Lorem Ipsum</h3>
                  <h5>(Avialable)</h5>
                </div>
                <span>Studio</span>
                <p className="price">$150/night</p>
                <div className="decription-wrapper">
                  <h4>Property description</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </p>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour.
                  </p>
                  <div className="form-to-where-wrapper">
                    <div className="cost-head">
                      <h3>$150/night</h3>
                      <h3>2,100</h3>
                    </div>
                    <div className="dated-from-to-where-wrap">
                      <input type="date" className="form-control" />
                      <span className="hyphen">-</span>
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                  <button className="btn" onClick={() => setModalShow(true)}>
                    Reserve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* rental appartment-section ends here*/}
      {/* Rooms section starts here */}
      <section className="rooms">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>Luxurious Rooms</h3>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <figure>
                  <Link to="/ReservationForm">
                    <img src={luxaryrooms} className="img-fluid" />
                  </Link>
                </figure>
                <h4>Full Room A</h4>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="content-wrapper">
                <figure>
                  <img src={luxaryrooms} className="img-fluid" />
                </figure>
                <h4>Full Room A</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Rooms section starts here */}
      {/* map section starts here */}
      <section className="map">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="100%"
                    height="655"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* map section ends here */}
      {/* nearby section starts here */}
      <section className="furnished">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content-wrapper">
                <h2 className="near-by"> Nearby landing list</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {Appartments.map((data, index) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <Appartments_card key={index} appartment={data} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* nearby section ends here */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Footer />
    </>
  );
};

export default RentalFurnishedAppartmentsDetail;
