import React from "react";
// css file starts here
import "../../assets/css/careers.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";
import { Link } from "react-router-dom";

const Careers = () => {
  const pagebannerprops = [
    {
      pagename: "Careers",
      breadname: "Careers",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* our mission wrapper starts here */}
      <section className="join-mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="content-wrapper text-center">
                <h2>Join Us On Our Mission</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          <div className="row lock-wrap ">
            <div className="col-lg-4">
              <div className="location-wrapper">
                <Link to="/jobs">
                  <h3>Administration</h3>
                  <h6>
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                    Boston
                  </h6>
                </Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="location-wrapper">
                <h3>Design</h3>
                <h6>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  Boston
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="location-wrapper">
                <h3>Engineering</h3>
                <h6>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  Boston
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="location-wrapper">
                <h3>Handyman</h3>
                <h6>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  Boston
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="location-wrapper">
                <h3>Marketing</h3>
                <h6>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  Boston
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="location-wrapper">
                <h3>Finance And Analytics</h3>
                <h6>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  Boston
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="location-wrapper">
                <h3>Handyman</h3>
                <h6>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  Boston
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="location-wrapper">
                <h3>Marketing</h3>
                <h6>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  Boston
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="location-wrapper">
                <h3>Finance And Analytics</h3>
                <h6>
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  Boston
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* our mission wrapper starts here */}
      <Footer />
    </>
  );
};

export default Careers;
