import { useEffect } from "react";
// bootstarp css & js files
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// bootstarp css & js files
// global css file
import "./assets/css/style.css";
import WOW from "wowjs";
import "animate.css";
// global css file
// import React Router
import PubilcRoutes from "./Router/PublicRouter";

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

  }, []);
  return (
    <>
      <PubilcRoutes />
    </>
  );
}

export default App;
