import React from "react";
// css file
import "../../assets/css/works.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";

const how_it_works = () => {
  const pagebannerprops = [
    {
      pagename: "How it works",
      breadname: "How it works",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* how it works section starts here */}
      <section className="works">
        <div className="container">
          <div className="row pb-5">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="content-wrapper text-center">
                <h2>How It Works</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          {/* points section starts here */}
          <div className="row works-list py-4">
            <div className="col-lg-1">
              <span className="number">1</span>
            </div>
            <div className="col-lg-11">
              <div className="content-wrapper">
                <h3>Find Your Landing</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                </p>
              </div>
            </div>
          </div>
          <div className="row works-list py-4">
            <div className="col-lg-1">
              <span className="number">2</span>
            </div>
            <div className="col-lg-11">
              <div className="content-wrapper">
                <h3>Get Approved</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
          <div className="row works-list py-4">
            <div className="col-lg-1">
              <span className="number">3</span>
            </div>
            <div className="col-lg-11">
              <div className="content-wrapper">
                <h3>Prepare for Move-in</h3>
                <p>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
          <div className="row works-list py-4">
            <div className="col-lg-1">
              <span className="number">4</span>
            </div>
            <div className="col-lg-11">
              <div className="content-wrapper">
                <h3>Move-in-Day</h3>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur.
                </p>
              </div>
            </div>
          </div>
          {/* points section ends here */}
        </div>
      </section>
      {/* how it works section starts here */}
      <Footer />
    </>
  );
};

export default how_it_works;
