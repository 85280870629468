import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
// jqurey file
import $ from "jquery";
// jqurey file
// import pages
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Faq from "../pages/Faq/faq";
import Blog from "../pages/Blog/blog";
import Blogdetail from "../pages/Blog/blogdetail";
import Contact from "../pages/contact-us/contact";
import How_it_works from "../pages/How-it-works/how-it-works";
import Refer_a_friend from "../pages/How-it-works/Refer-a-Friend";
import BecomeHost from "../pages/contact-us/BecomeHost";
import BecomePartner from "../pages/contact-us/BecomePartner";
import Careers from "../pages/Careers/Careers";
import Termsconditions from "../pages/Termsconditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Jobs from "../pages/Careers/jobs";
import Jobs_details from "../pages/Careers/jobs-details";
import Applynow from "../pages/Careers/Applynow";
import RentalFurnishedAppartments from "../pages/RentalFurnished/RentalFurnishedAppartments";
import RentalFurnishedDetails from "../pages/RentalFurnished/RentalFurnishedAppartmentsDetail";
import Signin from "../pages/Auth/Signin";
import Signup from "../pages/Auth/Signup";
import Entermail from "../pages/Auth/Entermail";
import Enterotp from "../pages/Auth/Enterotp";
import EnterNewpass from "../pages/Auth/EnterNewpass";
import ViewFloorplan from "../pages/ViewFloorplan/ViewFloorplan";
import ReservationForm from "../pages/RentalFurnished/ReservationForm";
import Payment from "../pages/payment/payment";
import Profile from "../pages/Profile/Profile";
// lodaing images
import loading from "../assets/img/loading-logo.svg";
import gsap from "gsap";
function PubilcRoutes() {
  // Mobile Nav
  $("document").ready(function () {
    $("header .canvas-icon i").click(function () {
      $("header .mobile-header").addClass("show");
    });

    $("header .mobile-header .cancel").click(function () {
      $("header .mobile-header").removeClass("show");
    });
  });
  // Mobile Nav
  // var currnet_item = document.querySelector('.profile div#v-pills-tab .nav-link.active').value ="y";
  // console.log(currnet_item);
  return (
    <>
      <div id="introduction" style={{ height: 0 }}>
        <div className="slide-1 slide" style={{ width: 0 }}></div>
        <div className="slide-2 slide"></div>
        <div className="image-container">
          <img
            className="banner-logo"
            src={loading}
            alt="banner"
            title="banner"
          />
        </div>
      </div>
      <BrowserRouter forceRefresh>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detail" element={<Blogdetail />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/works" element={<How_it_works />} />
          <Route path="/refer-a-friend" element={<Refer_a_friend />} />
          <Route path="/BecomeHost" element={<BecomeHost />} />
          <Route path="/BecomePartner" element={<BecomePartner />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Terms" element={<Termsconditions />} />
          <Route path="/Privacy" element={<PrivacyPolicy />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/Jobs-details" element={<Jobs_details />} />
          <Route path="/applynow" element={<Applynow />} />
          <Route
            path="/rentalappartment"
            element={<RentalFurnishedAppartments />}
          />
          <Route path="/RentalDetail" element={<RentalFurnishedDetails />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/entermail" element={<Entermail />} />
          <Route path="/enterotp" element={<Enterotp />} />
          <Route path="/enterpass" element={<EnterNewpass />} />
          <Route path="/ViewFloorplan" element={<ViewFloorplan />} />
          <Route path="/ReservationForm" element={<ReservationForm />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/Profile" element={<Profile />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default PubilcRoutes;
