import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// partner images starts here
import partner1 from "../assets/img/partner1.png";
import partner2 from "../assets/img/partner2.png";
import partner3 from "../assets/img/partner3.png";
import partner4 from "../assets/img/partner4.png";
import partner5 from "../assets/img/partner5.png";
import testimonails_person from "../assets/img/testimonials-img.png";
import Testimonials_card from "./testimonials_card";
// partner images ends here

const testimonials = () => {
  var setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var testimonials_slider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const Reviewitem = [
    {
      id: 1,
      name: "Jason Williams",
      designation: "Diagnostic Medical Sonographer",
      img: testimonails_person,
      review:
        "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 2,
      name: "Jason Williams",
      designation: "Diagnostic Medical Sonographer",
      img: testimonails_person,
      review:
        "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 3,
      name: "Jason Williams",
      designation: "Diagnostic Medical Sonographer",
      img: testimonails_person,
      review:
        "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      id: 1,
      name: "Jason Williams",
      designation: "Diagnostic Medical Sonographer",
      img: testimonails_person,
      review:
        "  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];
  return (
    // <!-- testimonials section starts here -->
    <section className="testimonials">
      <div className="container">
        <div className="row pb-5">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="content-wrapper text-center   animate__fadeInDown">
              <h2>
              Check out our Customer Reviews
              </h2>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
        <div className="row">
          <div className="col-lg-5">
            <div className="partner-wrapper">
              <h4 className="text-center   animate__fadeInDown">Our Partners</h4>
              <ul>
                <li>
                  <img src={partner1} className="  animate__fadeIn" alt="" />
                </li>
                <li>
                  <img src={partner2} className="  animate__fadeIn" alt="" />
                </li>
                <li>
                  <img src={partner3} className="  animate__fadeIn" alt="" />
                </li>
                <li>
                  <img src={partner4} className="  animate__fadeIn" alt="" />
                </li>
                <li>
                  <img src={partner5} className="  animate__fadeIn" alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 px-0">
          <Slider {...testimonials_slider}>
            {Reviewitem.map((Reviewitem, index) => {
              return (
                
                  <div key={index} className="testimonials-carousel">
                    <Testimonials_card  Reviewitem={Reviewitem} />
                   </div> 
              );
            })}
              </Slider>
          </div>
        </div>
      </div>
    </section>
    // {/* <!-- testimonials section ends here --> */}
  );
};

export default testimonials;
