import React, { useState } from "react";
import { Grommet, RadioButton } from "grommet";
// css file starts here
import "../../assets/css/applynow.css";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";

const Applynow = () => {
  const [checked, setChecked] = useState(false);

  const pagebannerprops = [
    {
      pagename: "Careers",
      breadname: "Careers",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* Apply Now form starts here */}
      <section className="ApplyNow">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="applynow-form-wrapper">
                <div className="content-wrapper text-center">
                  <h2>Apply Now</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer.
                  </p>
                </div>
                <form className="pt-5">
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="firstname">First Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Julia"
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="lastname">Last Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write Last Name"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="Email">Email*</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="you@example.com"
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="Phone">Phone*</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Your Phone Number"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="City">City*</label>
                      <select
                        defaultValue="Select City"
                        className="form-control"
                      >
                        <option disabled>Select City</option>
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="State">State*</label>
                      <select
                        defaultValue="Select State"
                        className="form-control"
                      >
                        <option disabled>Select State</option>
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <label htmlFor="Postal">Postal Code*</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Postal Code"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="Postal">Address*</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Write Your Address"
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 align-self-end">
                      <label htmlFor="Education">Education*</label>
                      <select
                        defaultValue="Your Education"
                        className="form-control"
                      >
                        <option disabled>Your Education</option>
                      </select>
                    </div>
                    <div className="col-lg-6 align-self-end">
                      <label className="d-block" htmlFor="Tanning">
                        {" "}
                        Tanning Salon Experience
                      </label>
                      <div className="form-check form-check-inline">
                        {/* <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          defaultValue="option1"
                          defaultChecked
                        /> */}
                        <RadioButton
                          name="radio"
                          checked={checked}
                          label="Yes"
                          onChange={(event) => setChecked(event.target.checked)}
                          defaultChecked
                        />
                        {/* <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Yes
                        </label> */}
                      </div>
                      <div className="form-check form-check-inline">
                        {/* <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          defaultValue="option2"
                        /> */}
                        <RadioButton
                          name="radio"
                          checked={checked}
                          label="No"
                          onChange={(event) => setChecked(event.target.checked)}
                        />
                        {/* <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          No
                        </label> */}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="City">City*</label>
                      <select
                        defaultValue="Select City"
                        className="form-control"
                      >
                        <option disabled>Select City</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="State">State*</label>
                      <select
                        defaultValue="Select State"
                        className="form-control"
                      >
                        <option disabled>Select State</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 align-self-end">
                      <label htmlFor="City">
                        Do you have any obligations, such as a job, school, etc
                        would change your availability?
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="List Your obligation"
                      />
                    </div>
                    <div className="col-lg-6 align-self-end">
                      <label htmlFor="City">
                        What skills/strengths can you bring to this role?
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Write Your Skills"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6">
                      <label htmlFor="City">
                        How did you hear about this position?
                      </label>
                      <select
                        defaultValue="Select position"
                        className="form-control"
                      >
                        <option disabled>Select position</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="City">
                        If referred, Please list the name below.
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Write Name"
                      />
                    </div>
                  </div>
                  <div className="form-group row pb-5">
                    <div className="col-lg-6">
                      <label htmlFor="City">Comments?</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Your Comments"
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="City">Upload Resume</label>
                      <input
                        type="file"
                        // placeholder="Write Name"
                      />
                    </div>
                  </div>
                  <div className="form-group chick d-flex justify-content-between">
                    <div className="form-check  checkbox-teal form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        defaultValue="option1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        Proident, sunt in culpa qui officia deserunt mollit anim{" "}
                        id est laborum.
                      </label>
                    </div>
                    <button className="btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
      {/* Apply Now form ends here */}
      <Footer />
    </>
  );
};

export default Applynow;
