import React from "react";
// css file start
import "../../assets/css/about.css";
// page images
import About_img from "../../assets/img/about-img.png";
// icons section images starts
import icon1 from "../../assets/img/icon1.png";
import icon2 from "../../assets/img/icon2.png";
import icon3 from "../../assets/img/icon3.png";
// icons section images ends
// import header and footer start
import Header from "../../components/header";
import Footer from "../../components/footer";
// import header and footer ends
// import page header
import PageBanner from "../../components/PageBanner";
// import testimonails Component
import Testimonials from "../../components/testimonials";
const About = () => {
  const pagebannerprops = [
    {
      pagename: "About us",
      breadname: "About us",
    },
  ];
  return (
    <>
      <Header />
      {/* page Banner starts here*/}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* page Banner ends here*/}
      {/* about us section starts here */}
      <section className="pageAbout">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-wrapper">
                <h3>About Us</h3>
                <h2>Welcoming to Future of Living</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  tempor arcu non ligula convallis, vel tincidunt ipsum posuere.
                  Fusce sodales lacus ut pellentes sollicitudin. Duis iaculis,
                  arcu ut hendrerit pharetra, elit augue pulvinar magna, a
                  consectetur eros quam magna,
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  tempor arcu non ligula convallis, vel tincidunt ipsum posuere.
                </p>
                {/* icons list */}
                <div className="row">
                  <div className="col-md-4">
                    <div className="icon-wrapper text-center">
                      <figure>
                        <img src={icon1} className="img-fluid" alt="" />
                      </figure>
                      <div className="icon-content-wrapper">
                        <p>Lorem ipsum dolor sit amet,</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="icon-wrapper text-center">
                      <figure>
                        <img src={icon2} className="img-fluid" alt="" />
                      </figure>
                      <div className="icon-content-wrapper">
                        <p>Lorem ipsum dolor sit amet,</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="icon-wrapper text-center">
                      <figure>
                        <img src={icon3} className="img-fluid" alt="" />
                      </figure>
                      <div className="icon-content-wrapper">
                        <p>Lorem ipsum dolor sit amet,</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* icons list */}
              </div>
            </div>
            <div className="col-lg-6 my-auto">
              <div className="about-img-wrapper">
                <figure>
                  <img className="img-fluid" src={About_img} />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about us section ends here */}
      {/* <!-- testimonials section start here --> */}
      <Testimonials />
      {/* <!-- testimonials section ends here --> */}
      <Footer />
    </>
  );
};

export default About;
