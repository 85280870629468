import React from "react";
// import header & footer
import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBanner from "../../components/PageBanner";
// icons
import partner_img from "../../assets/img/partner.png";

const BecomePartner = () => {
  const pagebannerprops = [
    {
      pagename: "Become A Partner",
      breadname: "Become A Partner",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* contact us form starts here */}
      <section className="Contact-form-wrapper">
        <div className="container">
          {/* heading section starts here */}
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="content-wrapper text-center">
                <h2>Become A Partner</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
          {/* heading section ends here */}
          {/* contact form start here */}
          <div className="row pt-5">
            <div className="col-lg-6">
              <div className="form-wrapper">
                <form>
                  <div className="form-group">
                    <label htmlFor="First Name">First Name</label>
                    <input
                      type="text"
                      placeholder="Lorem Ipsum"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="YourName">Last Name</label>
                    <input
                      type="text"
                      placeholder="Lorem Ipsum"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="YourName">Email</label>
                    <input
                      type="email"
                      placeholder="yourname@email.com"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="YourName">Phone</label>
                    <input
                      type="number"
                      placeholder="+00 123 456 789"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="YourName">
                      How many communities you manage?
                    </label>
                    <select className="form-control">
                      <option selected disabled>
                        Select Communities
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <button className="form-btn">Apply Now</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 my-auto">
              <figure>
                <img className="img-fluid" src={partner_img} />
              </figure>
            </div>
          </div>
          {/* contact form start here */}
        </div>
      </section>
      {/* contact us form ends here */}
      <Footer />
    </>
  );
};

export default BecomePartner;
