import React from "react";
// css file
import "../../assets/css/blog.css";
// import header and footer
import Header from "../../components/header";
import Footer from "../../components/footer";
// page header start
import PageBanner from "../../components/PageBanner";
import Blog_card from "../../components/blog_card";
// page header ends
// blog images
import minimalist from "../../assets/img/minimalist.png";
import explore from "../../assets/img/explorer.png";
import travel from "../../assets/img/travel.png";
import rates1 from "../../assets/img/rates1.png";
import rates7 from "../../assets/img/rates7.png";
import rates9 from "../../assets/img/rates9.png";
const blog = () => {
  const pagebannerprops = [
    {
      pagename: "Blogs",
      breadname: "Blogs",
    },
  ];
  const blogcontent = [
    {
      id: 1,
      img: minimalist,
      blogname: "The minimalist",
      blogdes:
        "Guests looking to live & travel light that opt for furnished over unfurnished, a minimalism mindset, and an environmentally-conscious approach",
      postdate: "Nov 7, 2021",
    },
    {
        id: 2,
        img: explore,
        blogname: "The explorer",
        blogdes:
          "Renters eager to hop around, excited to experiment, and keen to explore a new apartment, neighborhood or city without ever needing to commit.",
        postdate: "Nov 7, 2021",
      },
      {
        id: 1,
        img: travel,
        blogname: "The Business travel",
        blogdes:
          "Employees looking for more space & less cost than hotels via partnerships with HR & travel teams at 500+ companies in the world.",
        postdate: "Nov 7, 2021",
      },
      {
        id: 1,
        img: rates1,
        blogname: "Lorem Ipsum is Simply Dummy",
        blogdes:
          "Guests looking to live & travel light that opt for furnished over unfurnished, a minimalism mindset, and an environmentally-conscious approach",
        postdate: "Nov 7, 2021",
      },
      {
        id: 1,
        img: rates7,
        blogname: "Lorem Ipsum is Simply Dummy",
        blogdes:
          "Renters eager to hop around, excited to experiment, and keen to explore a new apartment, neighborhood or city without ever needing to commit",
        postdate: "Nov 7, 2021",
      },
      {
        id: 1,
        img: rates9,
        blogname: "Lorem Ipsum is Simply Dummy",
        blogdes:
          "Employees looking for more space & less cost than hotels via partnerships with HR & travel teams at 500+ companies in the world.",
        postdate: "Nov 7, 2021",
      },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* blog pages blogs */}
      <section className="blog-page">
        <div className="container">
          <div className="row">
            {blogcontent.map((data,index) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <Blog_card key={index} blogcontent={data} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* blog pages blogs */}
      <Footer />
    </>
  );
};

export default blog;
