import React from "react";
import Slider from "react-slick/lib/slider";
import wifi from "../assets/img/wifi.svg";
import bathroom from "../assets/img/bathroom.svg";
import bedroom from "../assets/img/bedroom.svg";
import sqt from "../assets/img/sqt.svg";

const Appartments = (props) => {
  const { appartment } = props;
  // banner-slider
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // banner-slider
  return (
    <>
      <div className="card-wrapper">
        <Slider className="card-img" {...settings}>
          <img src={appartment.img} className="img-fluid" alt="" />
          <img src={appartment.img} className="img-fluid" alt="" />
          <img src={appartment.img} className="img-fluid" alt="" />
          <img src={appartment.img} className="img-fluid" alt="" />
        </Slider>
        <a href="#">
          <div className="card-content-wrapper">
            <p className="description mb-0">{appartment.des}</p>
            <h4 className="appartment-name">{appartment.name}</h4>

            <ul className="specification">
              <li>
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.12"
                  height="23.873"
                  viewBox="0 0 24.12 23.873"
                >
                  <g id="Layer_2" data-name="Layer 2" transform="translate(0)">
                    <g id="Layer_2-2" data-name="Layer 2">
                      <path
                        id="Path_16849"
                        data-name="Path 16849"
                        d="M18.441,18.8H.358A.359.359,0,0,1,0,18.441V.358A.359.359,0,0,1,.358,0H18.441A.359.359,0,0,1,18.8.358V18.441A.359.359,0,0,1,18.441,18.8ZM.716.716V18.083H18.083V.716Z"
                        transform="translate(1.893 3.194)"
                        fill="#333"
                      />
                      <path
                        id="Path_16850"
                        data-name="Path 16850"
                        d="M23.265,22.492V1.213a.358.358,0,0,0-.358-.358H1.389L2.244,0H1.213L0,1.213,1.213,2.427H2.244l-.855-.855H22.541v20.92l-.848-.855V22.66l1.213,1.213L24.12,22.66V21.636Z"
                        fill="#333"
                      />
                    </g>
                  </g>
                </svg>
                {appartment.sqt}
              </li>
              <li>
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.474"
                  height="24.686"
                  viewBox="0 0 28.474 24.686"
                >
                  <g
                    id="Layer_2"
                    data-name="Layer 2"
                    transform="translate(0 0)"
                  >
                    <g id="Layer_2-2" data-name="Layer 2">
                      <path
                        id="Path_16900"
                        data-name="Path 16900"
                        d="M26.516,24.686H1.941v-.747H5.057a7.123,7.123,0,0,1-4.17-6.388V15.2a1.3,1.3,0,0,1,.4-2.533H26.829V3.556A2.774,2.774,0,0,0,24.111.849h-.062a2.787,2.787,0,0,0-2.771,2.589A3.279,3.279,0,0,1,22.287,7.8a.382.382,0,0,1-.326.186.433.433,0,0,1-.19-.052l-.665-.388-.747,1.284-.65-.365.747-1.293L17.968,5.753,17.258,7l-.651-.365.71-1.255L16.7,5.02a.364.364,0,0,1-.141-.508,3.286,3.286,0,0,1,4-1.45A3.527,3.527,0,0,1,24.047,0c.083,0,.167,0,.25.009a3.522,3.522,0,0,1,3.271,3.547v9.175H27.5a1.3,1.3,0,0,1,.972,1.24,1.289,1.289,0,0,1-.9,1.233v2.346a7.121,7.121,0,0,1-4.162,6.388h3.109v.746ZM8.367,23.939H20.1l.155,0a6.54,6.54,0,0,0,6.57-6.39V15.272H11.878v4.565a.379.379,0,0,1-.373.374H5.752a.379.379,0,0,1-.374-.374V15.272H1.643v2.279a6.54,6.54,0,0,0,6.57,6.39Zm-2.242-5.7v1.219h5.006V18.238Zm0-4.819v4.072h5.006V13.419Zm5.753,0v1.105H27.173a.553.553,0,0,0,0-1.105Zm-10.595,0a.553.553,0,1,0,0,1.105H5.378V13.419ZM19.421,3.6a2.552,2.552,0,0,0-.662.087,2.5,2.5,0,0,0-1.337.882l4.371,2.518a2.553,2.553,0,0,0,.089-1.6A2.549,2.549,0,0,0,19.421,3.6Z"
                        fill="#333"
                      />
                      <path
                        id="Rectangle_619"
                        data-name="Rectangle 619"
                        d="M0,.744V0H1.7V.744Z"
                        transform="matrix(0.493, -0.87, 0.87, 0.493, 17.721, 8.38)"
                        fill="#333"
                      />
                      <path
                        id="Rectangle_620"
                        data-name="Rectangle 620"
                        d="M0,.743V0H1.708V.743Z"
                        transform="translate(16.188 11.188) rotate(-60.45)"
                        fill="#333"
                      />
                      <path
                        id="Rectangle_621"
                        data-name="Rectangle 621"
                        d="M0,.743V0H1.7V.743Z"
                        transform="translate(18.04 10.578) rotate(-60.47)"
                        fill="#333"
                      />
                      <path
                        id="Rectangle_622"
                        data-name="Rectangle 622"
                        d="M0,.745V0H1.705V.745Z"
                        transform="matrix(0.493, -0.87, 0.87, 0.493, 15.915, 9.061)"
                        fill="#333"
                      />
                    </g>
                  </g>
                </svg>
                {appartment.bathroom}
              </li>
              <li>
                <svg
                  className="mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="22.15"
                  viewBox="0 0 33 22.15"
                >
                  <g
                    id="Layer_2"
                    data-name="Layer 2"
                    transform="translate(0 0)"
                  >
                    <g id="Layer_2-2" data-name="Layer 2">
                      <path
                        id="Path_16878"
                        data-name="Path 16878"
                        d="M32.66,22.15H30.74a.338.338,0,0,1-.34-.329v-4H2.6v4a.338.338,0,0,1-.34.329H.34A.338.338,0,0,1,0,21.821V12.06C0,11,1.29,10.11,3,10V2.39a2.418,2.418,0,0,1,.717-1.7A2.387,2.387,0,0,1,5.4,0H27.6a2.389,2.389,0,0,1,1.683.689A2.418,2.418,0,0,1,30,2.39V10c1.71.081,3,.942,3,2v9.82A.338.338,0,0,1,32.66,22.15Zm-1.59-4.33v3.6h1.26v-3.6Zm-30.4,0v3.6H1.93v-3.6Zm0-2.039v1.37H32.33v-1.37H.67Zm2.7-5.19c-1.438,0-2.7.659-2.7,1.41v3.08H32.33V12c0-.751-1.262-1.41-2.7-1.41ZM19,4.631H25.74A1.385,1.385,0,0,1,27.11,6v4H29.3V2.39A1.735,1.735,0,0,0,27.57.671H5.43A1.734,1.734,0,0,0,3.7,2.39V9.9H5.89v-4A1.386,1.386,0,0,1,7.26,4.531H14A1.371,1.371,0,0,1,15.37,5.9v4h2.26v-4A1.375,1.375,0,0,1,19,4.631ZM7.26,5.3a.7.7,0,0,0-.7.7v4H14.7l-.01-4A.7.7,0,0,0,14,5.3ZM19,5.2h0a.7.7,0,0,0-.7.75v4H26.43l.02-4.05a.7.7,0,0,0-.7-.7H19Z"
                        fill="#333"
                      />
                    </g>
                  </g>
                </svg>
                {appartment.bedroom}
              </li>
            </ul>
            <div className="d-flex justify-content-between">
              <p className="price">
                from <span className="bold">${appartment.price}</span> /night
              </p>
              <p className="price">
                Available{" "}
                <span className="bold">{appartment.availability}</span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default Appartments;
