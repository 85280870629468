import React from "react";
// import header and footer
import Header from "../../components/header";
import Footer from "../../components/footer";
// page header start
import PageBanner from "../../components/PageBanner";
// blog details images
import blogdetail_img from "../../assets/img/blogdetail.png";
import comment_img from "../../assets/img/comment-img.png";
import user_pro from "../../assets/img/user-pro.png";
const blogdetail = () => {
  const pagebannerprops = [
    {
      pagename: "Blog Detail",
      breadname: "Blog Detail",
    },
  ];
  return (
    <>
      <Header />
      {/* Page Banner  */}
      {pagebannerprops.map((data, index) => {
        return <PageBanner key={index} pagebannerprops={data} />;
      })}
      {/* Page Banner  */}
      {/* blog detail section starts here */}
      <section className="blog-detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-detail-wrapper">
                <div className="blog-img-wrapper">
                  <figure>
                    <img
                      className="blog-detail-img img-fluid"
                      src={blogdetail_img}
                    />
                  </figure>
                </div>
                <div className="blog-content-wrapper">
                  <h2 className="blog-title">
                    Lorem ipsum dolor sit amet, consectetur adipiscing.
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                    ea commodo consequat. Duis aute irure dolor in reprehenderit
                    in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est
                    laborum.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                    ea commodo consequat. Duis aute irure dolor in reprehenderit
                    in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est
                    laborum.
                  </p>
                </div>
                <div className="blog-comments-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="comment-heading-wrapper">
                        <h2>Comment</h2>
                        <h5>2 Comments</h5>
                      </div>
                    </div>
                  </div>
                  <div className="comment-warp pt-4">
                    <div className="row single-comment pb-4">
                      <div className="col-sm-2">
                        <figure>
                          <img
                            className="comment-img img-fluid"
                            src={comment_img}
                          />
                        </figure>
                      </div>
                      <div className="col-sm-10">
                        <div className="user-comment-content">
                          <h4>
                            <span className="user-name">Joeby Ragpa</span> -{" "}
                            <span className="date">
                              {" "}
                              12 April, 2014 at 16:50
                            </span>
                          </h4>
                          <p>
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination.
                          </p>
                          <button className="comment-reply">Reply</button>
                        </div>
                        <div className="replyed-comment">
                          <div className="row replyed-iem">
                            <div className="col-sm-2">
                              <figure>
                                <img
                                  className="user-replyed-img img-fluid"
                                  src={comment_img}
                                />
                              </figure>
                            </div>
                            <div className="col-sm-10">
                              <div className="user-comment-content">
                                <h4>
                                  <span className="user-name">Joeby Ragpa</span>{" "}
                                  -{" "}
                                  <span className="date">
                                    {" "}
                                    12 April, 2014 at 16:50
                                  </span>
                                </h4>
                                <p>
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                  the hidden and mysterious subconscious.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row replyed-iem">
                            <div className="col-sm-2">
                              <figure>
                                <img
                                  className="user-replyed-img img-fluid"
                                  src={comment_img}
                                />
                              </figure>
                            </div>
                            <div className="col-sm-10">
                              <div className="user-comment-content">
                                <h4>
                                  <span className="user-name">Joeby Ragpa</span>{" "}
                                  -{" "}
                                  <span className="date">
                                    {" "}
                                    12 April, 2014 at 16:50
                                  </span>
                                </h4>
                                <p>
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row replyed-iem">
                            <div className="col-sm-2">
                              <figure>
                                <img
                                  className="user-replyed-img img-fluid"
                                  src={comment_img}
                                />
                              </figure>
                            </div>
                            <div className="col-sm-10">
                              <div className="user-comment-content">
                                <h4>
                                  <span className="user-name">Joeby Ragpa</span>{" "}
                                  -{" "}
                                  <span className="date">
                                    {" "}
                                    12 April, 2014 at 16:50
                                  </span>
                                </h4>
                                <p>
                                  We possess within us two minds. So far I have
                                  written only of the conscious mind. I would
                                  now like to introduce you to your second mind,
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="add-comment">
                          <figure>
                            <img src={user_pro} className="img-fluid" />
                          </figure>
                          <button className="rep">
                            <span>Add reply</span>{" "}
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row single-comment pt-4">
                      <div className="col-sm-2">
                        <figure>
                          <img
                            className="comment-img img-fluid"
                            src={comment_img}
                          />
                        </figure>
                      </div>
                      <div className="col-sm-10">
                        <div className="user-comment-content">
                          <h4>
                            <span className="user-name">Joeby Ragpa</span> -{" "}
                            <span className="date">
                              {" "}
                              12 April, 2014 at 16:50
                            </span>
                          </h4>
                          <p>
                            We possess within us two minds. So far I have
                            written only of the conscious mind. I would now like
                            to introduce you to your second mind, the hidden and
                            mysterious subconscious. Our subconscious mind
                            contains such power and complexity that it literally
                            staggers the imagination.
                          </p>
                          <button className="comment-reply">Reply</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-comment">
                  <div className="row">
                    <div className="col-lg-8">
                      <h2>leave a Comment</h2>
                      <form>
                        <div className="form-group row">
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Name"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="email"
                              placeholder="Email"
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-12">
                            <textarea
                              rows="4"
                              cols="100"
                              placeholder="Comment"
                              className="form-control"
                            ></textarea>
                          </div>
                          <div className="col-md-12">
                            <button className="btn">Submit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* blog detail section starts here */}
      <Footer />
    </>
  );
};

export default blogdetail;
